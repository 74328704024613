import { Paper, Skeleton, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useContext, useEffect } from "react";
import TagManager from "react-gtm-module";
import { getConfig, getProperties } from "../../Services/Data.service";
import UserContext from "../../Contexts/UserContext";
import { loginDathic } from "../../Services/auth.service";
import SuggestStoreForm from "../Search/SuggestStoreForm";

export default function SuggestFormPage() {
  const theme = useTheme();

  const userContext = useContext(UserContext);

  const storeLabel =
    userContext.state?.orgProperties?.storeNameReplacement || "Store";
  const productLabel =
    userContext.state?.orgProperties?.productNameReplacement || "Product";

  useEffect(() => {
    loginDathic(
      process.env.REACT_APP_DEFAULT_USERNAME,
      process.env.REACT_APP_DEFAULT_USERPWD
    )
      .then((dathicCredential) => {
        let accessToken;
        if (dathicCredential?.access_token?.length) {
          const { user_id, access_token } = dathicCredential;
          accessToken = access_token;
          userContext.setState((oldState) => ({
            ...(oldState || {}),
            accessToken: access_token,
            userId: user_id,
          }));
          getProperties(accessToken).then((properties) =>
            userContext.setState((oldState) => ({
              ...(oldState || {}),
              properties,
            }))
          );
        }
        return getConfig(accessToken);
      })
      .then((config) => {
        userContext.setState((oldState) => ({
          ...(oldState || {}),
          config: config?.store_locator,
          orgProperties: config?.properties,
        }));
        const organizationId = window.DATHIC_ORG_ID;
        const org_id = "/" + (organizationId || process.env.REACT_APP_ORG_ID);
        TagManager.initialize({
          gtmId: "GTM-M7P8TV3",
          events: {
            flavorFilterChange: "flavorFilterChange",
            chainFilterChange: "chainFilterChange",
            typeFilterChange: "typeFilterChange",
            componentVisible: "componentVisible",
            searchChange: "searchChange",
          },
          dataLayer: {
            GAMeasurementId: config?.store_locator?.GAMeasurementId,
            UATrackingId: config?.store_locator?.UATrackingId,
            organization_id: org_id,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.setState]);

  useEffect(() => {
    if (!!userContext.state?.config) {
      window.spDathic("enableFormTracking");
    }
  }, [userContext.state]);

  return (
    <form
      id="search-block-form"
      style={{
        width: "100vw",
        height: "100vh",
        backgroundImage: `url(${
          userContext.state?.config?.searchBlockBackground ||
          "https://dathicstorelocator.s3.amazonaws.com/app/static/media/search-block-bg.png"
        })`,
        backgroundSize: "cover",
        borderRadius: theme.shape.borderRadius,
        overflow: "hidden",
      }}
    >
      <Stack
        width="100%"
        height="100%"
        direction="column"
        alignItems="center"
        spacing={3}
        padding={2}
        style={{
          backdropFilter: "brightness(0.4)",
          WebkitBackdropFilter: "brightness(0.4)",
        }}
        justifyContent="center"
      >
        {!userContext.state?.config ? (
          <Skeleton
            variant="text"
            sx={{ fontSize: "1.5rem", width: "24rem" }}
          />
        ) : (
          <Typography
            variant="h5"
            color="white"
            fontWeight="bold"
            textAlign="center"
          >
            Suggest a {storeLabel}
            <Typography color="white" fontWeight="bold">
              Didn't find a {productLabel} at a specific {storeLabel}? Fill out
              this form to let us know you want our {productLabel} there.
            </Typography>
          </Typography>
        )}
        {!userContext.state?.config ? (
          <Skeleton variant="rounded" width={300} height={170} />
        ) : (
          <Paper
            elevation={0}
            style={{
              padding: 10,
              background: theme.palette.primary.main,
              width: 300,
            }}
          >
            <Stack direction="column" spacing="10px" height="100%">
              <SuggestStoreForm />
            </Stack>
          </Paper>
        )}
      </Stack>
    </form>
  );
}
