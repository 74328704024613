import React, { useEffect } from "react";

export default function RedirectURL() {
  const params = new URLSearchParams(window.location.search);
  const redirectUrl = params.get("redirect");
  useEffect(() => {
    if (redirectUrl) {
      try {
        const url = new URL(decodeURIComponent(redirectUrl));
        setTimeout(() => {
          window.location.replace(url);
        }, 2000);
      } catch (error) {
        window.location.pathname = "/";
        window.location.hash = "/";
      }
    } else {
      window.location.pathname = "/";
      window.location.hash = "/";
    }
  }, [redirectUrl]);

  return <></>;
}
