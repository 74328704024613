import { createTheme } from "@mui/material/styles";
import _ from "lodash";

var WebFont = require("webfontloader");

export function GetTheme(storeLocatorConfig) {
  const borderRadius = 12;
  const fontType = storeLocatorConfig?.fontType || "Roboto";

  WebFont.load({
    google: {
      families: [fontType],
    },
  });

  const default_theme = createTheme({
    palette: _.merge(
      {
        primary: {
          main: "#032536",
          900: "#032536",
          800: "#395463",
          700: "#516976",
          600: "#758994",
          500: "#9CABB3",
          400: "#0299F7",
          300: "#3BAAF4",
          200: "#52B5F5",
          100: "#76C5F7",
          50: "#9DD6F9",
        },
        secondary: {
          main: "#0299F7",
          400: "#0299F7",
          300: "#3BAAF4",
          200: "#52B5F5",
          100: "#76C5F7",
          50: "#9DD6F9",
        },
      },
      storeLocatorConfig?.theme?.palette || {}
    ),
    typography: {
      useNextVariants: true,
      fontFamily: [fontType, "Helvetica", "Arial", "sans-serif"].join(","),
      fontSize: Number(storeLocatorConfig?.fontSize?.replace("px", "")),
    },
    shape: {
      borderRadius,
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            ...(ownerState.variant === "solid" && {
              backgroundColor: theme.palette.primary.main,
            }),
          }),
        },
      },
      MuiAutocomplete: {
        styleOverrides: { root: { paddingTop: 0, paddingBottom: 0 } },
      },
      MuiFormControl: {
        styleOverrides: { root: { marginTop: 0, marginBottom: 0 } },
      },
      MuiTextField: {
        defaultProps: {
          variant: "filled",
          InputProps: { disableUnderline: true },
        },
        variants: [
          {
            props: { variant: "filled" },
            style: { background: "white", borderRadius },
          },
        ],
        styleOverrides: { root: { marginTop: 0, marginBottom: 0 } },
      },
      MuiInputBase: {
        styleOverrides: { root: { backgroundColor: "transparent" } },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            ...(ownerState.variant === "contained" && {
              color: theme.palette.primary.contrastText,
              ":hover": {
                color: theme.palette.secondary.contrastText,
                backgroundColor: theme.palette.secondary.main,
              },
            }),
          }),
        },
      },
    },
  });

  const theme = createTheme(default_theme, storeLocatorConfig?.theme || {});
  return theme;
}
