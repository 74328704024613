import WatchIcon from "@mui/icons-material/QueryBuilder";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { withTheme } from "@mui/styles";
import { mean, round } from "mathjs";
import PropTypes from "prop-types";
import React, { Component, useContext, useState } from "react";
import ReactStars from "react-stars";
import { rrulestr } from "rrule";

import { Block, Directions, Edit } from "@mui/icons-material";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import BuyOnline from "@mui/icons-material/ShoppingCart";
import _ from "lodash";
import { DateTime } from "luxon";
import TagManager from "react-gtm-module";
import Slider from "react-slick";
import MapContext from "../../Contexts/MapContext";
import UserContext, { RESOURCES } from "../../Contexts/UserContext";
import {
  getDeliveryEntitiesForStores,
  getProductsForStores,
} from "../../Services/Data.service";
import {
  getStoreName,
  mapsSelector,
  validateSchedule,
} from "../../Utils/Utils";

function ContactInfo({ item, deliveryEntities }) {
  const { deliveryInventory } = useContext(MapContext);
  const storeInventory = deliveryInventory?.filter((i) =>
    (deliveryEntities || [])
      .map((de) => de.delivery_entity_uid)
      .includes(i.delivery_entity_uid)
  );
  const deliveryEntityPhone = storeInventory?.find(
    (i) => !!i?.delivery_entity?.phone
  )?.delivery_entity?.phone;
  const phone = item.phone || deliveryEntityPhone;
  return (
    <>
      {phone && (
        <Button
          href={"tel:" + phone}
          variant="text"
          style={{ justifyContent: "flex-start" }}
          color="inherit"
        >
          {phone}
        </Button>
      )}
    </>
  );
}

function OpenInfo({ item }) {
  const isSmall = useMediaQuery("(max-width: 360px)");
  return (
    <>
      {item.loading_opening_hours && (
        <CircularProgress
          style={{ marginTop: "10px" }}
          size={24}
          color={"primary"}
        />
      )}
      {!item.loading_opening_hours && typeof item.open_now === "boolean" && (
        <Button
          color={item.open_now ? "success" : "error"}
          startIcon={<WatchIcon />}
          size="small"
        >
          {isSmall ? "" : item.open_now ? "Open" : "Closed"}
        </Button>
      )}
      {!item.loading_opening_hours && typeof item.open_now !== "boolean" && (
        <Button
          color="inherit"
          startIcon={<WatchIcon />}
          href={
            "https://www.google.com/maps/search/?api=1&query=" +
            encodeURI([item.chain, item.address].filter((c) => !!c).join(" "))
          }
          size="small"
          target="_blank"
          rel="noopener"
        >
          Hours
        </Button>
      )}
    </>
  );
}

function BuyOnlineLink({ item, deliveryEntities }) {
  const { deliveryInventory } = useContext(MapContext);
  const storeInventory = deliveryInventory?.filter((i) =>
    (deliveryEntities || [])
      .map((de) => de.delivery_entity_uid)
      .includes(i.delivery_entity_uid)
  );
  const isSmall = useMediaQuery("(max-width: 360px)");
  const deliveryStoreUrl = storeInventory?.find(
    (i) => !!i?.delivery_entity?.store_url
  )?.delivery_entity?.store_url;
  const deliveryProductUrl = storeInventory?.find(
    (i) => !!i?.delivery_product?.buy_url_product
  )?.delivery_product?.buy_url_product;
  const link_buy = deliveryProductUrl || deliveryStoreUrl || item.link_buy;
  return (
    <>
      {link_buy && (
        <Button
          className="store-buy-online-btn"
          id={`${item.id}`}
          startIcon={<BuyOnline />}
          onClick={() => window.open(link_buy)}
          sx={{
            color: "#0299F7",
          }}
          size="small"
        >
          {isSmall ? "" : "Buy online"}
        </Button>
      )}
    </>
  );
}

function DirectionsButton({ item }) {
  const isSmall = useMediaQuery("(max-width: 360px)");
  return (
    <Button
      startIcon={<Directions />}
      color="inherit"
      size="small"
      sx={{
        color: "black",
      }}
      onClick={() => mapsSelector(item.latitude, item.longitude)}
    >
      {isSmall ? "" : "Directions"}
    </Button>
  );
}

function Products({
  userContext,
  products,
  storeLocatorConfig,
  overrideImage,
  storeChain,
  classes,
}) {
  const mapContext = useContext(MapContext);
  const [sliderIndex, setSliderIndex] = useState(0);

  const getProductImage = (item) => {
    const cleanChain = `${storeChain}`
      .replace(/[^a-zA-Z0-9]/g, "")
      .toLowerCase();
    const productImageForChain = item?.chain_images?.[cleanChain];
    const imageUrl = productImageForChain || item?.url;
    return imageUrl;
  };

  const SliderCard = ({ productItem, index }) => {
    const [imageFailed, setImageFailed] = useState(false);
    const product = mapContext.products?.find(
      (p) => p.id === productItem?.product_id
    );
    return (
      <div style={{ width: products?.length > 1 ? 150 : undefined }}>
        <div style={{ textAlign: "center" }}>
          {imageFailed || !(overrideImage || getProductImage(product)) ? (
            <Typography
              color={"tomato"}
              border={"1px solid tomato"}
              borderRadius={1}
              p={1}
              bgcolor="whitesmoke"
              width="100%"
              height={150}
            >
              No Image
            </Typography>
          ) : (
            <img
              alt="product"
              src={overrideImage || getProductImage(product)}
              width="100%"
              height={150}
              style={{
                margin: "auto",
                maxHeight: 250,
                objectFit: "contain",
                padding: 10,
              }}
              onError={() => setImageFailed(true)}
            />
          )}
          {index === sliderIndex && (
            <div
              style={{
                textAlign: "center",
                width: products?.length > 1 ? 250 : undefined,
                position: products?.length > 1 ? "relative" : undefined,
                left: products?.length > 1 ? -50 : undefined,
              }}
            >
              <Typography variant="body2">
                {userContext?.formatText(product?.name)}
              </Typography>
              <Typography variant="caption">
                {product?.description || product?.category || product?.flavor}
              </Typography>
              {productItem?.price && (
                <Typography variant="caption">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                  }).format(productItem?.price_oz || productItem?.price) +
                    (productItem?.price_oz ? " / oz" : "")}
                </Typography>
              )}
              {storeLocatorConfig.storeDetails?.showProductReviews && (
                <div>
                  <ReactStars
                    count={5}
                    edit={false}
                    value={productItem?.score_num || -1}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const GridCard = ({ productItem }) => {
    const [imageFailed, setImageFailed] = useState(false);
    return (
      <Paper
        style={{
          overflow: "hidden",
          height: "100%",
        }}
      >
        <Stack
          direction={"column"}
          alignItems={"center"}
          spacing={1}
          p={1}
          height={"100%"}
        >
          {imageFailed ||
          !(
            overrideImage ||
            getProductImage(
              mapContext.products?.find((p) => p.id === productItem.product_id)
            )
          ) ? (
            <Typography
              color={"tomato"}
              border={"1px solid tomato"}
              borderRadius={1}
              p={1}
              bgcolor="whitesmoke"
            >
              No Image
            </Typography>
          ) : (
            <img
              alt="product"
              src={
                overrideImage ||
                getProductImage(
                  mapContext.products?.find(
                    (p) => p.id === productItem.product_id
                  )
                )
              }
              width={80}
              style={{ maxHeight: 250, objectFit: "contain" }}
              onError={() => setImageFailed(true)}
            />
          )}
          <Typography variant="body2">
            {userContext?.formatText(
              mapContext.products?.find((p) => p.id === productItem.product_id)
                ?.name
            )}
          </Typography>
          {!!mapContext.products?.find((p) => p.id === productItem.product_id)
            ?.category && (
            <Typography variant="caption" alignContent={"end"} flex={1}>
              {userContext?.formatText(
                mapContext.products?.find(
                  (p) => p.id === productItem.product_id
                )?.category
              )}
            </Typography>
          )}
        </Stack>
      </Paper>
    );
  };

  const CustomSliderPaging = ({ product, index }) => {
    const [imageFailed, setImageFailed] = useState(false);
    return !imageFailed && (overrideImage || getProductImage(product)) ? (
      <img
        alt={product?.name}
        src={overrideImage || getProductImage(product)}
        height={20}
        width={20}
        style={{
          objectFit: "contain",
          border:
            sliderIndex === index
              ? `solid 1px ${classes.palette.primary[400]}`
              : undefined,
        }}
        onError={() => setImageFailed(true)}
      />
    ) : (
      <Block
        style={{
          height: 20,
          width: 20,
          backgroundColor: "whitesmoke",
          borderRadius: 10,
          color: "tomato",
          border:
            sliderIndex === index
              ? `solid 1px ${classes.palette.primary[400]}`
              : undefined,
        }}
      />
    );
  };

  return !!products?.length ? (
    <>
      {storeLocatorConfig.storeDetails?.productsVisualization === "grid" && (
        <Grid
          container
          spacing={2}
          maxHeight={"20rem"}
          overflow={"auto"}
          marginTop={2}
        >
          {products
            ?.filter(
              (productItem) =>
                !!mapContext.products?.find(
                  (p) => p.id === productItem?.product_id
                )
            )
            ?.map((productItem) => (
              <Grid xs={4}>
                <GridCard productItem={productItem} />
              </Grid>
            ))}
        </Grid>
      )}
      {!storeLocatorConfig.storeDetails?.productsVisualization &&
        !!products?.length &&
        (products?.length > 1 ? (
          <Slider
            dots
            arrows
            centerMode
            autoplay
            pauseOnHover
            centerPadding="0px"
            variableWidth
            slidesToShow={1}
            slidesToScroll={1}
            afterChange={setSliderIndex}
            appendDots={(dots) => (
              <Grid overflow={"auto"} position={"relative"}>
                {dots}
              </Grid>
            )}
            customPaging={(i) => {
              const product = mapContext.products?.find(
                (p) =>
                  p.id ===
                  products?.filter(
                    (productItem) =>
                      !!mapContext.products?.find(
                        (p) => p.id === productItem?.product_id
                      )
                  )?.[i]?.product_id
              );
              return <CustomSliderPaging product={product} index={i} />;
            }}
          >
            {products
              ?.filter(
                (productItem) =>
                  !!mapContext.products?.find(
                    (p) => p.id === productItem?.product_id
                  )
              )
              .map((productItem, index) => (
                <SliderCard
                  key={productItem?.product_id}
                  productItem={productItem}
                  index={index}
                />
              ))}
          </Slider>
        ) : (
          <Slider slidesToShow={1}>
            {products
              ?.filter(
                (productItem) =>
                  !!mapContext.products?.find(
                    (p) => p.id === productItem?.product_id
                  )
              )
              .map((productItem, index) => (
                <SliderCard
                  key={productItem?.product_id}
                  productItem={productItem}
                  index={index}
                />
              ))}
          </Slider>
        ))}
    </>
  ) : !!overrideImage ? (
    <Stack direction="column" alignItems="center">
      <img
        alt="product"
        src={overrideImage}
        width={150}
        style={{ maxHeight: 250, objectFit: "contain" }}
      />
    </Stack>
  ) : (
    <></>
  );
}

class StoreDetailsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storeProducts: [],
      deliveryEntities: [],
    };
  }

  setProducts() {
    const { result } = this.props;
    const reduceProducts = (acc, product) => {
      if (product && !acc.find((ac) => ac.product_id === product.product_id)) {
        acc.push(product);
      }
      return acc;
    };
    if (result?.products?.length) {
      this.setState({
        storeProducts: result.products?.reduce(reduceProducts, []),
      });
    } else {
      getProductsForStores([result?.id]).then((response) => {
        this.setState({
          storeProducts: response?.reduce(reduceProducts, []) || [],
        });
      });
    }
  }

  setDeliveryEntities() {
    const { result } = this.props;
    if (result?.delivery_entities?.length) {
      this.setState({ deliveryEntities: result.delivery_entities });
    } else {
      getDeliveryEntitiesForStores([result?.id]).then((response) => {
        this.setState({ deliveryEntities: response || [] });
      });
    }
  }

  componentDidMount() {
    this.setProducts();
    this.setDeliveryEntities();
  }

  componentDidUpdate(prevProps) {
    if (this.props.result?.id !== prevProps.result?.id) {
      this.setState({ storeProducts: [], deliveryEntities: [] }, () => {
        this.setProducts();
        this.setDeliveryEntities();
      });
    } else if (!this.props.result?.id) {
      this.setState({
        storeProducts: [],
        deliveryEntities: [],
      });
    }
  }

  stripAddress() {
    const { address, city, state, zipcode } = this.props.result;
    const addressRegex = new RegExp(`,|${city}|${state}|${zipcode}`, "gi");
    return address.replace(addressRegex, "").replace(/\s+/g, " ").trim();
  }
  render() {
    const storeLocatorConfig =
      (this.context || this.props.context)?.state?.config ||
      this.props.storeLocatorConfig;
    const orgProperties =
      (this.context || this.props.context)?.state?.orgProperties ||
      this.props.orgProperties;
    const productLabel =
      (this.context || this.props.context)?.state?.orgProperties
        ?.productNameReplacement || "Product";
    const _products = this.state.storeProducts || [];
    const featuredContent =
      !!this.props.result?.featured_content &&
      (typeof this.props.result?.featured_content === "string"
        ? JSON.parse(this.props.result?.featured_content)
        : this.props.result?.featured_content || []
      ).filter((fc) => {
        if (fc.startDate || fc.endDate || fc.recurringRule) {
          const todayValid = validateSchedule(
            fc.startDate,
            fc.endDate,
            fc.recurringRule
          );
          return todayValid;
        }
        return true;
      });
    const startDateDateTime = DateTime.fromSQL(this.props.result.start_date);
    const endDateDateTime = DateTime.fromSQL(this.props.result.end_date);
    const todayValid = validateSchedule(
      this.props.result.start_date,
      this.props.result.end_date,
      this.props.result.recurrence_rule
    );
    return (
      <div>
        <Stack direction="row">
          <Stack direction="column" flex={1}>
            <Typography
              variant="body2"
              style={{
                lineHeight: "1.5em",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "260px",
              }}
            >
              {(this.context || this.props.context)?.formatText(
                getStoreName(
                  this.props.result,
                  storeLocatorConfig,
                  orgProperties?.noChainNameReplacement
                )
              )}

              {(this.context || this.props.context)?.checkAdminPermission(
                RESOURCES.STORES_UPDATE
              ) && (
                <Tooltip title="Edit store">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      window.parent.postMessage(
                        {
                          action: "edit-store",
                          data: this.props.result,
                        },
                        "*"
                      );
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
              )}
            </Typography>
            {storeLocatorConfig.storeDetails?.showType && (
              <Typography
                variant="caption"
                color="textSecondary"
                style={{
                  lineHeight: "1.5em",
                  overflow: "hidden",
                }}
              >
                {this.props.result.type}
              </Typography>
            )}
            {storeLocatorConfig?.storeDetails?.showReviews && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                <ReactStars
                  count={5}
                  edit={false}
                  value={
                    this.props.result?.reviews?.length
                      ? round(
                          mean(_.map(this.props.result?.reviews, "rating")),
                          1
                        )
                      : 0
                  }
                />
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  style={{ marginLeft: 5 }}
                >
                  ({this.props.result?.reviews?.length || 0})
                </Typography>
              </div>
            )}
            {storeLocatorConfig?.storeDetails?.showAddress && (
              <>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  style={{
                    lineHeight: "1.5em",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    maxWidth: "260px",
                  }}
                >
                  {this.stripAddress()}
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  style={{
                    lineHeight: "1.5em",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {[this.props.result.city, this.props.result.state]
                    .filter((c) => !!c)
                    .join(", ")}{" "}
                  {this.props.result.zipcode || ""}
                </Typography>
              </>
            )}
            {!!storeLocatorConfig?.storeDetails?.contactInfo && (
              <ContactInfo
                item={this.props.result}
                deliveryEntities={this.state.deliveryEntities}
              />
            )}
          </Stack>
          <Stack direction="column" alignItems="flex-start">
            {!!storeLocatorConfig?.storeDetails?.buyOnlineLink && (
              <BuyOnlineLink
                item={this.props.result}
                deliveryEntities={this.state.deliveryEntities}
              />
            )}
            {!!storeLocatorConfig?.storeDetails?.openInfo && (
              <OpenInfo item={this.props.result} />
            )}
            {!!storeLocatorConfig?.storeDetails?.directionsButton && (
              <DirectionsButton item={this.props.result} />
            )}
          </Stack>
        </Stack>
        <Typography variant="caption">
          {storeLocatorConfig.storeDetails?.showReviews &&
            !!storeLocatorConfig?.storeDetails?.numberOfReviewsToShow && (
              <Typography
                variant="caption"
                style={{
                  display: "block",
                  lineHeight: "1.5em",
                  overflow: "hidden",
                }}
              >
                {this.props.result.reviews?.[0]?.title || ""}
              </Typography>
            )}
          {storeLocatorConfig?.storeDetails?.showDescription &&
            this.props.result.description && (
              <Typography variant="caption">
                {this.props.result.description || ""}
              </Typography>
            )}

          {!!(this.props.result.start_date && this.props.result.end_date) && (
            <>
              <Divider variant="middle" />
              <Stack
                direction="column"
                spacing={1}
                padding={1}
                width="100%"
                alignItems="center"
                style={{
                  lineHeight: "1.5em",
                  textAlign: "center",
                }}
              >
                {todayValid && (
                  <Typography variant="caption" color="green">
                    Now available
                  </Typography>
                )}
                {!todayValid && (
                  <Typography variant="caption">
                    {`Please verify availability with location`}
                  </Typography>
                )}
                {DateTime.now() < startDateDateTime && (
                  <Typography variant="caption">
                    {`Starting ${startDateDateTime.toRelativeCalendar()}`}
                  </Typography>
                )}
                {DateTime.now() > endDateDateTime &&
                  !endDateDateTime.hasSame(DateTime.now(), "day") && (
                    <Typography variant="caption">
                      {`Ended ${endDateDateTime.toRelativeCalendar()}`}
                    </Typography>
                  )}
                {!this.props.result.recurrence_rule &&
                  startDateDateTime < DateTime.now() &&
                  DateTime.now() < endDateDateTime && (
                    <Typography variant="caption">
                      {`You can find us at this location until ${endDateDateTime.toLocaleString(
                        DateTime.DATE_MED_WITH_WEEKDAY
                      )}`}
                    </Typography>
                  )}
                {!!this.props.result.recurrence_rule && (
                  <>
                    <Typography variant="caption">
                      {`You ${
                        DateTime.now() > endDateDateTime &&
                        !endDateDateTime.hasSame(DateTime.now(), "day")
                          ? "could"
                          : "can"
                      } find us at this location ${rrulestr(
                        this.props.result.recurrence_rule
                      ).toText()}`}
                    </Typography>
                  </>
                )}
                <Typography variant="caption">
                  <Chip
                    size="small"
                    label={startDateDateTime.toLocaleString(
                      DateTime.DATE_MED_WITH_WEEKDAY
                    )}
                  />{" "}
                  to{" "}
                  <Chip
                    size="small"
                    label={endDateDateTime.toLocaleString(
                      DateTime.DATE_MED_WITH_WEEKDAY
                    )}
                  />
                </Typography>
              </Stack>
            </>
          )}
          {!!featuredContent?.length && (
            <>
              <Divider variant="middle" />
              <Stack
                direction="row"
                spacing={5}
                width="100%"
                style={{ overflowX: "auto" }}
                alignItems="center"
                justifyContent={
                  featuredContent?.length === 1 ? "center" : "flex-start"
                }
              >
                {featuredContent?.map((s) => (
                  <Paper
                    className="featured-content"
                    id={`${s.name}`}
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      width: 300,
                      minWidth: 300,
                      display: "flex",
                      flexDirection: "column",
                      overflow: "hidden",
                      cursor: s.link ? "pointer" : undefined,
                    }}
                    onClick={() => {
                      TagManager.dataLayer({
                        dataLayer: {
                          event: "featuredContentClick",
                          category: s.name,
                          label: this.props.result.name,
                          data: this.props.result.id,
                          city: this.props.result.city,
                        },
                      });
                      if (s.link) {
                        window.open(s.link);
                      }
                    }}
                  >
                    {!!s.image && (
                      <Box width="100%" height={s.imageHeight || "200px"}>
                        <img
                          height="100%"
                          width="100%"
                          src={s.image}
                          style={{ objectFit: s.imageFit || "cover" }}
                          alt="featured content"
                        />
                      </Box>
                    )}
                    {!![s.title, s.message, s.callToAction].filter((c) => !!c)
                      .length && (
                      <Stack direction="column" alignItems="flex-start" p={1}>
                        {!!s.title && (
                          <Typography
                            style={{
                              fontWeight: "bold",
                              fontSize: 18,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "100%",
                            }}
                          >
                            {s.title}
                          </Typography>
                        )}
                        {!!s.message && (
                          <Typography
                            style={{
                              fontSize: 14,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "100%",
                              maxHeight: "4.5em",
                            }}
                            noOfLines={3}
                          >
                            {s.message}
                          </Typography>
                        )}
                        {!!s.callToAction && (
                          <Button
                            sx={{
                              maxWidth: "100%",
                              overflow: "hidden",
                            }}
                            variant="contained"
                          >
                            {s.callToAction}
                          </Button>
                        )}
                      </Stack>
                    )}
                  </Paper>
                ))}
              </Stack>
            </>
          )}
          {!!storeLocatorConfig.storeDetails?.additionalServices?.length && (
            <>
              <Divider variant="middle" />
              <Stack
                direction="row"
                spacing={5}
                width="100%"
                style={{ overflowX: "auto" }}
                alignItems="flex-start"
                justifyContent="center"
              >
                {storeLocatorConfig.storeDetails.additionalServices.map((s) => (
                  <Stack direction="column">
                    {s.icon ? (
                      <img
                        alt="additional service icon"
                        src={s.icon}
                        style={{
                          objectFit: "cover",
                          borderRadius: 10,
                          width: "3.5rem",
                          height: "3.5rem",
                        }}
                      />
                    ) : (
                      <RoomServiceIcon
                        style={{ width: "3.5rem", height: "3.5rem" }}
                      />
                    )}
                    <Typography
                      variant="caption"
                      textAlign="center"
                      margin={0}
                      marginTop={0}
                    >
                      {s.label}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </>
          )}
          <Divider variant="middle" />

          {storeLocatorConfig?.storeDetails?.productsAvailable &&
            !!this.state.storeProducts?.length && (
              <div style={{ marginTop: 10 }}>
                {!storeLocatorConfig?.hideProductsLabel && (
                  <Typography variant="subtitle2">
                    {productLabel}s sold at this store:
                  </Typography>
                )}
                {!storeLocatorConfig?.hideAvailabilityMessage && (
                  <Typography variant="subtitle4" color="textSecondary">
                    {storeLocatorConfig?.customAvailabilityMessage ||
                      "Please check availability with the store. Inventory may vary."}
                  </Typography>
                )}

                <Products
                  products={_products}
                  classes={this.props.theme}
                  storeLocatorConfig={storeLocatorConfig}
                  userContext={this.context || this.props.context}
                  storeChain={this.props.result.chain}
                />
              </div>
            )}
          {storeLocatorConfig?.storeDetails?.productsAvailable &&
            !this.state.storeProducts?.length && (
              <div style={{ marginTop: 10 }}>
                <Typography variant="subtitle2" textAlign="center">
                  {storeLocatorConfig?.customAvailabilityMessage ||
                    `Check store for ${productLabel} availability.`}
                </Typography>
                {storeLocatorConfig.storeCardDefaultImage && (
                  <Products
                    userContext={this.context || this.props.context}
                    overrideImage={storeLocatorConfig.storeCardDefaultImage}
                    storeLocatorConfig={storeLocatorConfig}
                    storeChain={this.props.result.chain}
                  />
                )}
              </div>
            )}
        </Typography>
      </div>
    );
  }
}

StoreDetailsComponent.propTypes = {
  result: PropTypes.object.isRequired,
};
StoreDetailsComponent.contextType = UserContext;

export default withTheme(StoreDetailsComponent);
