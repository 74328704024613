import Home from "../Views/Home/Home";
import SearchBlock from "../Views/Search/SearchBlock";
import SuggestFormPage from "../Views/SuggestForm/SuggestFormPage";
import RedirectURL from "../RedirectURL";

const routes = [
  { path: "/search", name: "Search", component: SearchBlock },
  { path: "/suggest_form", name: "Suggest_Form", component: SuggestFormPage },
  { path: "/url_redirect", name: "url_redirect", component: RedirectURL },
  { path: "/home/:region", name: "Home", component: Home },
];

export default routes;
