import PropTypes from "prop-types";
import React, { Component } from "react";

import { Box, Container, Tab, Tabs } from "@mui/material";
import { withTheme } from "@mui/styles";

import TagManager from "react-gtm-module";
import Slider from "react-slick";
import UserContext from "../../Contexts/UserContext";
import {
  doGetStoreReviews,
  doSearchInGooglePlaces,
} from "../../Services/Data.service";
import StoreDetailsComponent from "./StoreDetailsComponent";
import StoreReviewsComponent from "./StoreReviewsComponent";

const initialState = {
  reviews: null,
  opening_hours: null,
  loading_opening_hours: false,
  loading: false,
  tab_index: 0,
  data: null,
  workingSecondaryImages: [],
  failedPrimaryImage: false,
};

class ResultDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      workingSecondaryImages: props.result.secondary_images || [],
    };
  }

  searchInGooglePlaces = async (data) => {
    const { context, storeLocatorConfig, openInfo } = this.props;
    const _storeLocatorConfig =
      (this.context || context)?.state?.config || storeLocatorConfig;
    if (!_storeLocatorConfig?.storeDetails?.openInfo) {
      this.setState({
        opening_hours: undefined,
        loading_opening_hours: false,
      });
      return;
    }

    if (openInfo) {
      this.setState({
        opening_hours: openInfo,
        loading_opening_hours: false,
      });
      return;
    }

    this.setState({ loading_opening_hours: true });
    doSearchInGooglePlaces(data)
      .then((result) => {
        this.setState({
          opening_hours: result?.opening_hours,
          loading_opening_hours: false,
        });
      })
      .catch(() =>
        this.setState({
          opening_hours: undefined,
          loading_opening_hours: false,
        })
      );
  };

  getStoreReviews = async (store_id) => {
    const { context, storeLocatorConfig, reviews } = this.props;
    const _storeLocatorConfig =
      (this.context || context)?.state?.config || storeLocatorConfig;
    if (!_storeLocatorConfig?.storeDetails?.showReviews) {
      return;
    }

    if (reviews?.length) {
      this.setState({ reviews });
      return;
    }

    this.setState({ loading: true });
    doGetStoreReviews(store_id).then((result) => {
      this.setState({ reviews: result });
    });
  };

  componentDidMount() {
    this.searchInGooglePlaces(this.props.result.id);
    this.getStoreReviews(this.props.result.id);

    TagManager.dataLayer({
      dataLayer: {
        event: "componentVisible",
        category: "Store Details",
        "gtm.elementTarget": `${this.props.result.id}`,
        label: this.props.result.name,
        data: `${this.props.result.latitude},${this.props.result.longitude}`,
        city: this.props.result.city,
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.result?.id !== prevProps.result?.id) {
      this.setState({
        ...initialState,
        workingSecondaryImages: this.props.result.secondary_images || [],
      });
      this.searchInGooglePlaces(this.props.result.id);
      this.getStoreReviews(this.props.result.id);

      TagManager.dataLayer({
        dataLayer: {
          event: "componentVisible",
          category: "Store Details",
          "gtm.elementTarget": `${this.props.result.id}`,
          label: this.props.result.name,
          data: `${this.props.result.latitude},${this.props.result.longitude}`,
          city: this.props.result.city,
        },
      });
    }
  }

  handleChange = (event, value) => {
    this.setState({ tab_index: value });
  };

  render() {
    const {
      context,
      storeLocatorConfig,
      orgProperties,
      result,
      theme,
    } = this.props;
    const {
      opening_hours,
      loading_opening_hours,
      reviews,
      failedPrimaryImage,
    } = this.state;
    const open_now = opening_hours?.open_now;

    const { tab_index, data, workingSecondaryImages } = this.state;
    const _reviews =
      data && data.content && data.content.data && data.content.data.length > 0
        ? data.content.data[0].reviews
        : [];
    const _storeLocatorConfig =
      (this.context || context)?.state?.config || storeLocatorConfig;
    const _orgProperties =
      (this.context || context)?.state?.config || orgProperties;

    const workingImages = [
      ...(!failedPrimaryImage && (result.image_url_large || result.image_url)
        ? [result.image_url_large || result.image_url]
        : []),
      ...workingSecondaryImages,
    ];

    return (
      <div style={{ paddingBottom: "10px" }}>
        {_storeLocatorConfig?.storeDetails?.showStoreImage &&
          !!workingImages.length && (
            <Box
              sx={{
                height: 300,
                width: "100%",
                borderRadius: `${theme.shape.borderRadius}px`,
                overflow: "hidden",
              }}
            >
              {workingImages > 1 && (
                <Slider dots arrows autoplay pauseOnHover>
                  {workingImages.map((img, x) => (
                    <div>
                      <img
                        src={img}
                        style={{
                          width: "100%",
                          height: 300,
                          objectFit: img ? "cover" : "contain",
                        }}
                        alt={result.address}
                        onError={() =>
                          x > 0
                            ? this.setState((old) => ({
                                workingSecondaryImages: old.workingSecondaryImages.filter(
                                  (img, i) => x !== i
                                ),
                              }))
                            : this.setState({ failedPrimaryImage: true })
                        }
                      />
                    </div>
                  ))}
                </Slider>
              )}
              {workingImages.length === 1 && (
                <img
                  src={workingImages[0]}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: workingImages[0] ? "cover" : "contain",
                  }}
                  alt={result.address}
                  onError={() => this.setState({ failedPrimaryImage: true })}
                />
              )}
            </Box>
          )}

        {_storeLocatorConfig?.storeDetails?.showReviews &&
          Number(_storeLocatorConfig?.storeDetails?.numberOfReviewsToShow) !==
            0 && (
            <Tabs
              value={tab_index}
              onChange={this.handleChange}
              variant="fullWidth"
            >
              <Tab key={0} label={"Info"} />
              <Tab key={1} label={"Reviews"} />
            </Tabs>
          )}

        <Container
          style={{
            paddingBottom: 20,
            paddingLeft: 10,
            paddingTop: 5,
            paddingRight: 5,
          }}
        >
          {tab_index === 0 ? (
            <StoreDetailsComponent
              result={{
                ...result,
                open_now,
                opening_hours: opening_hours,
                loading_opening_hours,
                reviews: reviews,
              }}
              storeLocatorConfig={_storeLocatorConfig}
              orgProperties={_orgProperties}
              context={this.context || context}
            />
          ) : (
            <StoreReviewsComponent
              reviews={_reviews}
              store_id={result.id}
              context={this.context || context}
            />
          )}
        </Container>
        <div></div>
      </div>
    );
  }
}

ResultDetails.propTypes = {
  result: PropTypes.object.isRequired,
};
ResultDetails.contextType = UserContext;

export default withTheme(ResultDetails);
