import { ArrowLeft } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  ListItem,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useContext, useState } from "react";
import TagManager from "react-gtm-module";
import MapContext from "../../Contexts/MapContext";
import UserContext from "../../Contexts/UserContext";

function CategoryFilter({ options, onChange, value }) {
  const [selected, setSelected] = useState(value || []);
  const [viewingMore, setViewingMore] = useState(false);
  const _value = value || selected;
  const _onChange = (e, option, checked) => {
    setSelected((oldValue) => {
      const newValue = checked
        ? Array.from(new Set([...oldValue, option]))
        : oldValue.filter((s) => s !== option);
      onChange?.(e, newValue);
      return newValue;
    });
  };
  const maxVisibleCategories = 5;

  return (
    <Stack direction={"row"} alignItems={"flex-start"}>
      {!viewingMore && (
        <Stack direction={"row"} spacing={1} flex={1} overflow={"auto"}>
          {(options || []).slice(0, maxVisibleCategories).map((option) => (
            <Chip
              label={option}
              variant={_value.includes(option) ? "filled" : "outlined"}
              color={_value.includes(option) ? "primary" : undefined}
              onClick={(e) => _onChange(e, option, !_value.includes(option))}
            />
          ))}
        </Stack>
      )}
      {viewingMore && (
        <Autocomplete
          sx={{ width: "100%" }}
          multiple
          limitTags={2}
          options={options}
          onChange={(event, option) => {
            setSelected(option);
            onChange?.(event, option);
          }}
          value={_value}
          size="small"
          open
          includeInputInList
          disableCloseOnSelect
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{ ...params.inputProps, style: { fontSize: "16px" } }}
              label="Category"
              variant="filled"
              fullWidth
              size="small"
              name={`filter-product-category`}
            />
          )}
          renderOption={(props, option, state, ownerState) => (
            <ListItem {...props} dense sx={{ fontSize: 14 }}>
              <Checkbox style={{ marginRight: 8 }} checked={state.selected} />
              {ownerState.getOptionLabel
                ? ownerState.getOptionLabel(option)
                : option}
            </ListItem>
          )}
        />
      )}
      {!viewingMore && options?.length > maxVisibleCategories && (
        <Button
          variant="text"
          color="inherit"
          size="small"
          onClick={() => setViewingMore(true)}
          sx={{ marginTop: "3px" }}
        >
          more
        </Button>
      )}
      {viewingMore && (
        <Stack direction={"column"}>
          <Button
            variant="text"
            color="inherit"
            onClick={(event) => {
              setSelected([]);
              onChange?.(event, []);
              setViewingMore(false);
            }}
          >
            Clear
          </Button>
          <Button
            variant="text"
            color="inherit"
            onClick={() => setViewingMore(false)}
          >
            Done
          </Button>
        </Stack>
      )}
    </Stack>
  );
}

export default function ProductFilterContent({
  selectedProducts = [],
  setSelectedProducts,
  isStrictSelection,
}) {
  const [searchValue, setSearchValue] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [failedImages, setFailedImages] = useState([]);
  const [viewingSelected, setViewingSelected] = useState(false);
  const [strictSelection, setStrictSelection] = useState(
    isStrictSelection || false
  );
  const [_selectedProducts, _setSelectedProducts] = useState(selectedProducts);
  const mapContext = useContext(MapContext);
  const userContext = useContext(UserContext);
  const isMobile = useMediaQuery("(max-width: 768px)");

  const minProductsForCategories = +(
    userContext.state?.config?.minProductsForCategories || 5
  );
  const storeLabel =
    userContext.state?.orgProperties?.storeNameReplacement || "Store";
  const validProducts = mapContext.products || [];
  const products = [
    ...(userContext.state?.config?.productPriority
      ?.map((id) => validProducts.find((p) => p.id === id))
      .filter((p) => !!p) || []),
    ...validProducts.filter(
      (p) => !userContext.state?.config?.productPriority?.includes(p.id)
    ),
  ].filter(
    (p) =>
      (viewingSelected
        ? _selectedProducts.includes(p.id)
        : !selectedCategories.length ||
          selectedCategories.includes(p.category)) &&
      JSON.stringify(p).toLowerCase().includes(searchValue.toLowerCase())
  );
  const categoryOptions = Array.from(
    new Set(
      [...(products || []), ...(mapContext.products || [])]
        .map((p) => p.category)
        .filter((c) => !!c)
    )
  );
  const allSelected = products.every((p) => _selectedProducts.includes(p.id));
  const allIsIndeterminate = !allSelected && _selectedProducts.length > 0;

  return (
    <Stack
      direction={"column"}
      p={2}
      spacing={isMobile ? 1 : 2}
      maxWidth={"25rem"}
      maxHeight={"calc(100vh - 35px)"}
      width={isMobile ? undefined : "25rem"}
    >
      {!viewingSelected && (
        <TextField
          label="Search for product"
          variant="filled"
          fullWidth
          size="small"
          name={`filter-Product-search`}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          inputProps={{ style: { fontSize: "16px" } }}
        />
      )}
      {!viewingSelected &&
        !!categoryOptions.length &&
        validProducts.length >= minProductsForCategories && (
          <CategoryFilter
            options={categoryOptions}
            onChange={(event, option) => {
              setSelectedCategories(option);
              TagManager.dataLayer({
                dataLayer: {
                  event: "flavorFilterChange",
                  category: "Product Category Filter",
                  label: option,
                  data: option,
                  snowplowParameters: {
                    terms: [`${option}`, `${option}`],
                    filters: {
                      "Product-category-Filter": `${option}`,
                    },
                  },
                },
              });
            }}
            value={selectedCategories}
          />
        )}
      <Stack direction={"row"} justifyContent={"space-between"}>
        {!viewingSelected && (
          <FormControlLabel
            control={
              <Checkbox
                checked={products.every((p) =>
                  _selectedProducts.includes(p.id)
                )}
                indeterminate={allIsIndeterminate}
                onChange={(e, checked) => {
                  _setSelectedProducts?.(
                    checked
                      ? Array.from(
                          new Set([
                            ..._selectedProducts,
                            ...products.map((p) => p.id),
                          ])
                        )
                      : _selectedProducts.filter(
                          (s) => !products.map((p) => p.id).includes(s)
                        )
                  );
                }}
              />
            }
            label="Select all"
          />
        )}
        {!!viewingSelected && (
          <Button
            variant="text"
            color="inherit"
            onClick={() => setViewingSelected(false)}
            startIcon={<ArrowLeft />}
          >
            Product search
          </Button>
        )}
        {!!_selectedProducts.length && !viewingSelected && (
          <Button
            variant="text"
            color="inherit"
            onClick={() => setViewingSelected(true)}
          >
            {_selectedProducts.length} selected
          </Button>
        )}
        {(!!searchValue.length || !!selectedCategories.length) && (
          <Button
            variant="text"
            color="inherit"
            onClick={() => {
              setSearchValue("");
              setSelectedCategories([]);
            }}
          >
            Clear filters
          </Button>
        )}
      </Stack>
      {!userContext.state.config?.hideAvailabilityMessage && (
        <Typography variant="subtitle2">
          {userContext.state.config?.customAvailabilityMessage ||
            "Always check availability with store"}
        </Typography>
      )}
      <Grid
        container
        spacing={2}
        flex={1}
        maxHeight={"20rem"}
        overflow={"auto"}
      >
        {products?.map((productItem) => (
          <Grid key={productItem.id} xs={isMobile ? 12 : 6} item>
            <Paper
              style={{
                overflow: "hidden",
                position: "relative",
                height: "100%",
              }}
            >
              <Checkbox
                checked={_selectedProducts.includes(productItem.id)}
                onChange={(e, checked) => {
                  const newSelection = Array.from(
                    new Set([
                      ..._selectedProducts.filter((s) => s !== productItem.id),
                      ...(checked ? [productItem.id] : []),
                    ])
                  );
                  if (newSelection.length === 0) setViewingSelected(false);
                  _setSelectedProducts?.(newSelection);
                }}
                style={{ position: "absolute", top: 0, left: 0 }}
              />
              <Stack
                direction={"column"}
                alignItems={"center"}
                spacing={1}
                p={1}
                height={"100%"}
              >
                {failedImages.includes(productItem.id) || !productItem?.url ? (
                  <Typography
                    color={"tomato"}
                    border={"1px solid tomato"}
                    borderRadius={1}
                    p={1}
                    bgcolor="whitesmoke"
                    width={isMobile ? 60 : 80}
                    style={{ maxHeight: 250 }}
                  >
                    No Image
                  </Typography>
                ) : (
                  <img
                    alt="product"
                    src={productItem?.url}
                    width={isMobile ? 60 : 80}
                    style={{ maxHeight: 250, objectFit: "contain" }}
                    onError={() =>
                      setFailedImages((old) => [...old, productItem.id])
                    }
                  />
                )}
                <Typography variant="body2">
                  {userContext?.formatText(productItem?.name)}
                </Typography>
                {!!productItem?.category && (
                  <Typography variant="caption" alignContent={"end"} flex={1}>
                    {userContext?.formatText(productItem?.category)}
                  </Typography>
                )}
              </Stack>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Stack direction={"row"}>
        {/* <FormControlLabel
          control={
            <Checkbox
              checked={!!strictSelection}
              onChange={(e, checked) => {
                setStrictSelection(!!checked);
              }}
            />
          }
          label={`${storeLabel} must have all selected products`}
        /> */}
        <Button
          fullWidth
          variant="contained"
          onClick={() =>
            setSelectedProducts(_selectedProducts, strictSelection)
          }
        >
          See {storeLabel}s
        </Button>
      </Stack>
    </Stack>
  );
}
