import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import React, { useContext, useEffect, useMemo, useState } from "react";
import MapContext from "../../Contexts/MapContext";
import UserContext from "../../Contexts/UserContext";
import { windowOpen } from "../../Utils/Utils";

const sourceLogos = (source) => {
  return `https://dathic-growth-files-public2.s3.us-east-1.amazonaws.com/store-locator-online-option-images/${(typeof source ===
  "string"
    ? source
    : ""
  ).replace?.(/[" "]/g, "")}.png`;
};

export default function BuyOnlineButton({ products }) {
  const mapContext = useContext(MapContext);
  const userContext = useContext(UserContext);
  const theme = useTheme();

  const isMobile = useMediaQuery("(max-width: 768px)");

  const [filteredProducts, setFilteredProducts] = useState([]);

  const searchFilters = useMemo(() => mapContext.searchFilters, [
    mapContext.searchFilters,
  ]);
  const deliveryInventory = useMemo(() => mapContext.deliveryInventory || [], [
    mapContext.deliveryInventory,
  ]);
  const visibleStatesOnMap = useMemo(
    () => mapContext.visibleStatesOnMap || [],
    [mapContext.visibleStatesOnMap]
  );

  const deliveryProducts = useMemo(
    () =>
      deliveryInventory
        ?.reduce(
          (acc, inv) =>
            acc.find(
              (item) => item.delivery_product_uid === inv.delivery_product_uid
            )
              ? acc
              : [...acc, inv],
          []
        )
        .map((inv) => inv.delivery_product)
        .filter((product) => !!product),
    [deliveryInventory]
  );

  useEffect(() => {
    if (searchFilters?.product) {
      setFilteredProducts(
        deliveryProducts?.filter((dp) =>
          products
            ?.find((product) => `${product.id}` === `${searchFilters.product}`)
            ?.delivery_products?.map((pdp) => pdp.delivery_product_uid)
            ?.includes(dp.uid)
        ) || []
      );
    } else {
      setFilteredProducts([]);
    }
  }, [deliveryProducts, products, searchFilters]);

  let deliveryEntities = [];
  if (userContext.state?.config?.additionalSources?.length) {
    deliveryEntities = userContext.state?.config?.additionalSources
      ?.filter(
        (s) =>
          s.deliveryArea !== "Some states" ||
          visibleStatesOnMap.some(
            (state) =>
              s.deliveryStates?.includes(state) ||
              s.deliveryStates
                ?.map((s) => (typeof s === "string" ? s : s?.key))
                .includes(state)
          )
      )
      ?.map((s) => ({
        ...s,
        isAdditionalSource: true,
      }));
  }
  deliveryEntities = deliveryEntities?.concat(
    deliveryInventory
      .reduce(
        (acc, inv) =>
          (filteredProducts.length &&
            !filteredProducts.find(
              (prod) => prod.uid === inv.delivery_product_uid
            )) ||
          acc.find(
            (item) => item.delivery_entity_uid === inv.delivery_entity_uid
          )
            ? acc
            : [...acc, inv],
        []
      )
      .map((inv) => inv.delivery_entity)
      .filter((entity) => !!entity)
  );
  return (
    <>
      {!isMobile && (
        <>
          <Typography color={theme.palette.primary.contrastText}>
            Retailers available:
          </Typography>
        </>
      )}
      <Stack
        direction={isMobile ? "row" : "column"}
        spacing={2}
        style={{ flex: 1, height: "100%", overflowY: "auto" }}
        padding={isMobile ? "30px" : undefined}
      >
        {[
          ...new Set(deliveryEntities?.map((entity) => entity?.source) || []),
        ].map((source) => {
          const additionalSource = deliveryEntities?.find(
            (de) => de.isAdditionalSource && de.source === source
          );
          return !additionalSource?.isHidden ? (
            <Grid item xs={6} key={source}>
              <Card
                sx={{
                  display: "flex",
                  width: isMobile ? 300 : undefined,
                  height: 135,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "66.66%",
                  }}
                >
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <div>
                      <Typography
                        style={{
                          border: "1px solid",
                          borderRadius: 5,
                          padding: "1px  6px",
                        }}
                        color={
                          additionalSource?.origin !== "dathic" ||
                          deliveryInventory
                            .filter(
                              (inv) =>
                                inv.source === source &&
                                (filteredProducts.length
                                  ? filteredProducts
                                  : deliveryProducts
                                ).find(
                                  (prod) =>
                                    prod.uid === inv.delivery_product_uid
                                )
                            )
                            .find(
                              (inv) =>
                                inv.is_product_available === true ||
                                inv.is_product_available !== false
                            )
                            ? "green"
                            : undefined
                        }
                      >
                        {additionalSource?.origin !== "dathic" ||
                        deliveryInventory
                          .filter(
                            (inv) =>
                              inv.source === source &&
                              (filteredProducts.length
                                ? filteredProducts
                                : deliveryProducts
                              ).find(
                                (prod) => prod.uid === inv.delivery_product_uid
                              )
                          )
                          .find(
                            (inv) =>
                              inv.is_product_available === true ||
                              inv.is_product_available !== false
                          )
                          ? "Available"
                          : "Not available"}
                      </Typography>
                    </div>
                    <Typography
                      component="div"
                      variant="h6"
                      style={{
                        lineHeight: "1.5em",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {source}
                    </Typography>
                  </CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      pl: 1,
                      pb: 1,
                    }}
                  >
                    {getBuyUrl(additionalSource, source) && (
                      <Button
                        className="buy-source-btn"
                        id={`${source}`}
                        variant="contained"
                        color="primary"
                        style={{ marginRight: 10 }}
                        onClick={() =>
                          windowOpen(
                            getBuyUrl(additionalSource, source),
                            "_blank"
                          )
                        }
                      >
                        Buy
                      </Button>
                    )}
                  </Box>
                </Box>
                {(additionalSource?.imgUrl || sourceLogos(source)) && (
                  <div
                    style={{
                      width: "33.33%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{ width: "100%", objectFit: "contain" }}
                      image={additionalSource?.imgUrl || sourceLogos(source)}
                      alt={`${source} logo`}
                    />
                  </div>
                )}
              </Card>
            </Grid>
          ) : (
            <></>
          );
        })}
      </Stack>
    </>
  );

  function getBuyUrl(additionalSource, source) {
    return (
      additionalSource?.buyUrl ||
      deliveryInventory.find(
        (inv) =>
          inv.source === source &&
          (filteredProducts.length ? filteredProducts : deliveryProducts)
            .map((p) => p.uid)
            .includes(inv.delivery_product_uid)
      )?.buy_product_store_url ||
      (filteredProducts.length ? filteredProducts : deliveryProducts).find(
        (prod) => prod.source === source
      )?.buy_url_product ||
      deliveryInventory.find(
        (inv) =>
          inv.source === source &&
          (filteredProducts.length ? filteredProducts : deliveryProducts)
            .map((p) => p.uid)
            .includes(inv.delivery_product_uid)
      )?.delivery_entity?.store_url
    );
  }
}
