import React, { Component, createRef } from "react";

import DeckGL from "@deck.gl/react";
import ReactMapGL, {
  FlyToInterpolator,
  GeolocateControl,
  Marker,
  NavigationControl,
  Popup,
} from "react-map-gl";

import { withTheme } from "@mui/styles";
import {
  calculateMaxMi,
  createLayerFromDataset,
  DEFAULT_MAP_ZOOM,
  getStoreName,
  MAX_ZOOM_GPS_LOCATION,
  MAX_ZOOM_HIGHLIGHTED_ICON,
} from "../../Utils/Utils";

import { WebMercatorViewport } from "@deck.gl/core";
import { Close } from "@mui/icons-material";
import { IconButton, Paper, SwipeableDrawer } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import _ from "lodash";
import TagManager from "react-gtm-module";
import { AutoSizer } from "react-virtualized";
import UserContext from "../../Contexts/UserContext";
import PinIcon from "../../Data/pin.png";
import {
  doGetProducts,
  getStatesFromBounds,
  getStoreLocatorInventory,
  getZipcodesInRadius,
} from "../../Services/Data.service";
import ResultDetails from "../Search/ResultDetails";
import SearchPanel from "../Search/SearchPanel";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiZGF0aGljIiwiYSI6ImNrYzg2cHBmMTE0ODQycXQ2dHEzdmZlajEifQ.OZvk_-izmIuFlYmkq7kRIw";
const DEFAULT_MAP_STYLE = "mapbox://styles/dathic/ckc8kk348173w1iozrqb1jvmm";
const DARK_MAP_STYLE = "mapbox://styles/dathic/clxnsusm302r601qoflhjdr9w";

export const PUERTO_RICO_POS = { lat: 18.224587, lng: -66.423609 };
export const US_POS = { lat: 37.09024, lng: -95.712891 };
export const CA_POS = { lat: 29.617821, lng: -81.70622 };

class Map extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const { mapContext, storeLocatorConfig, region } = this.props;

    this.mapRef = createRef();
    const INITIAL_LATITIUDE =
      storeLocatorConfig?.initialCoordinates?.latitude || 29.617821;
    const INITIAL_LONGITUDE =
      storeLocatorConfig?.initialCoordinates?.longitude || -81.70622;
    const INITIAL_ZOOM = storeLocatorConfig?.initialZoom || DEFAULT_MAP_ZOOM;

    mapContext.setMapContext({
      mapStyle:
        storeLocatorConfig?.mapTheme === "dark"
          ? DARK_MAP_STYLE
          : DEFAULT_MAP_STYLE,
      layers: [],
      highlight_result: { data: null },
      inTransition: false,
      isDragging: false,
      isZooming: false,
      viewport: {
        width: window.innerWidth,
        height: window.innerHeight,
        latitude: region === "pr" ? PUERTO_RICO_POS.lat : INITIAL_LATITIUDE,
        longitude: region === "pr" ? PUERTO_RICO_POS.lng : INITIAL_LONGITUDE,
        zoom: INITIAL_ZOOM,
        bearing: 0,
        pitch: 0,
        transitionDuration: 1000,
        transitionInterpolator: new FlyToInterpolator(),
        trackUserLocation: true,
      },
      toggleModalPresent: this.toggleModalPresent,
      updateViewport: this.updateViewport,
      setHoverResultItem: this.setHoverResultItem.bind(this),
      deliveryInventory: null,
      products: undefined,
      searchFilters: { chain: "", type: "", product: "" },
      hoverInfo: undefined,
      hoverResultItem: undefined,
      layerUpdates: 0,
    });
  }

  componentDidMount() {
    doGetProducts().then((response) => {
      this.props.mapContext.setMapContext((oldState) => ({
        ...oldState,
        products: response || [],
      }));
    });
  }

  updateViewport = (viewport) => {
    this.props.mapContext.setMapContext((oldState) => ({
      ...oldState,
      viewport: viewport,
    }));
  };

  toggleModalPresent = (data, reviews, index, openInfo) => {
    if (data && data.latitude && data.longitude) {
      this.highlightResultOnMap(data);

      this.props.mapContext.setMapContext((oldState) => ({
        ...oldState,
        highlight_result: { data: data, reviews, index, openInfo },
      }));
      this.fetchDelivery([data.latitude, data.longitude]);
    } else {
      this.props.mapContext.setMapContext((oldState) => ({
        ...oldState,
        highlight_result: { data: null },
      }));
    }
  };

  highlightResultOnMap = (data) => {
    let viewport = this.props.mapContext.viewport;
    viewport.latitude = data.latitude;
    viewport.longitude = data.longitude;
    viewport.zoom = Math.max(
      MAX_ZOOM_HIGHLIGHTED_ICON,
      this.props.mapContext.viewport.zoom
    );
    viewport.transitionDuration = 1000;
    this.props.mapContext.setMapContext((oldState) => ({
      ...oldState,
      viewport: viewport,
    }));
  };

  fetchDelivery = async (_coordinates) => {
    const map = this.mapRef.current.getMap();
    const { viewport } = this.props.mapContext;
    const { accessToken } = this.context.state || {};
    const latitude = _coordinates?.[0] || viewport?.latitude;
    const longitude = _coordinates?.[1] || viewport?.longitude;
    if (latitude && longitude && viewport?.zoom && accessToken) {
      try {
        const defaultZipRadiusMi = this.props.storeLocatorConfig
          ?.deliveryZipcodeRadiusMi;
        const uniqueZipcodes = await getZipcodesInRadius(
          latitude,
          longitude,
          Math.min(
            calculateMaxMi(latitude, viewport?.zoom),
            defaultZipRadiusMi || 5
          )
        );
        let extraInventory;
        if (uniqueZipcodes?.length) {
          this.props.mapContext.setMapContext((oldState) => ({
            ...oldState,
            deliveryInventory: null,
          }));
          const organizationId = window.DATHIC_ORG_ID;
          const org_id = organizationId || process.env.REACT_APP_ORG_ID;
          extraInventory = await getStoreLocatorInventory(
            undefined,
            uniqueZipcodes,
            [],
            org_id,
            [
              "id_auto_delivery_inventory",
              "is_product_available",
              "delivery_product_uid",
              "buy_product_store_url",
              "source",
              "delivery_entity_uid",
              "delivery_product.uid",
              "delivery_product.source",
              "delivery_product.buy_url_product",
              "delivery_entity.store_url",
              "delivery_entity.source",
              "delivery_entity.phone",
            ]
          );
        }
        const bounds = map.getBounds();
        const visibleStatesOnMap = await getStatesFromBounds(
          bounds.getWest(),
          bounds.getSouth(),
          bounds.getEast(),
          bounds.getNorth()
        );
        this.props.mapContext.setMapContext((oldState) => ({
          ...oldState,
          visibleStatesOnMap: visibleStatesOnMap?.states,
          deliveryInventory: [...(extraInventory || [])].reduce(
            (acc, i) =>
              acc.find(
                (item) =>
                  item.id_auto_delivery_inventory ===
                  i.id_auto_delivery_inventory
              )
                ? acc
                : [...acc, i],
            []
          ),
        }));
      } catch (error) {
        this.props.mapContext.setMapContext((oldState) => ({
          ...oldState,
          deliveryInventory: [],
        }));
      }
    } else {
      this.props.mapContext.setMapContext((oldState) => ({
        ...oldState,
        deliveryInventory: [],
      }));
    }
  };

  addLayer = (data, shouldFlyToCoordinates, geopoliticalRegion) => {
    const layer = createLayerFromDataset(
      data,
      this.toggleModalPresent,
      this.props.storeLocatorConfig,
      this.context.state?.properties,
      (hoverInfo) => {
        this.props.mapContext.setMapContext((oldState) => ({
          ...oldState,
          hoverInfo,
        }));
      },
      this.props.mapContext.layerUpdates + 1
    );

    let { viewport } = this.props.mapContext;

    const layers = [layer];

    if (shouldFlyToCoordinates) {
      viewport.latitude = shouldFlyToCoordinates.lat;
      viewport.longitude = shouldFlyToCoordinates.lng;

      let shouldFitBounds = false;
      switch (geopoliticalRegion) {
        case "street_address":
          viewport.zoom = DEFAULT_MAP_ZOOM + 4;
          break;
        case "locality":
          viewport.zoom = DEFAULT_MAP_ZOOM + 2;
          break;
        case "postal_code":
          viewport.zoom = DEFAULT_MAP_ZOOM + 4;
          break;
        case "country":
          viewport.zoom = DEFAULT_MAP_ZOOM - 4;
          break;
        default:
          shouldFitBounds = true;
          break;
      }

      if (shouldFitBounds) {
        let storesToFitInBounds = _.map(_.first(_.chunk(data, 10)), (d) => [
          d.longitude,
          d.latitude,
        ]);
        storesToFitInBounds.push([
          shouldFlyToCoordinates.lng,
          shouldFlyToCoordinates.lat,
        ]);

        if (storesToFitInBounds.length === 1) {
          storesToFitInBounds.push([
            shouldFlyToCoordinates.lng + 0.3,
            shouldFlyToCoordinates.lat + 0.3,
          ]);
        }
        const bounds = new WebMercatorViewport(viewport).fitBounds(
          storesToFitInBounds,
          {
            padding: 20,
            offset: [200, 0],
          }
        );

        viewport.latitude = bounds.latitude;
        viewport.longitude = bounds.longitude;
        viewport.zoom = bounds.zoom - 0.2;
      }
    }

    this.props.mapContext.setMapContext((prevState) => {
      return {
        ...prevState,
        layers: layers,
        viewport: viewport,
        layerUpdates: prevState.layerUpdates + 1,
      };
    });
  };

  setHoverResultItem(item) {
    this.props.mapContext.setMapContext((oldState) => ({
      ...oldState,
      hoverResultItem: item,
    }));
  }

  render() {
    const {
      theme,
      region,
      storeLocatorConfig,
      orgProperties,
      mapContext,
    } = this.props;
    const {
      highlight_result,
      trackUserLocation,
      viewport,
      layers,
      hoverInfo,
      hoverResultItem,
      mapStyle,
    } = mapContext;
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    const geolocateControl = (
      <GeolocateControl
        trackUserLocation={true}
        onViewportChange={(e) => {
          e.zoom = MAX_ZOOM_GPS_LOCATION;
          if (trackUserLocation) {
            TagManager.dataLayer({
              dataLayer: {
                event: "searchChange",
                category: "Search",
                action:
                  "Using user location: lat" +
                  e.latitude +
                  " lng: " +
                  e.longitude,
              },
            });
            this.props.mapContext.setMapContext((oldState) => ({
              ...oldState,
              viewport: e,
            }));
          }
          this.props.mapContext.setMapContext((oldState) => ({
            ...oldState,
            trackUserLocation: false,
          }));
        }}
      />
    );

    return (
      <React.Fragment>
        <SearchPanel
          addLayer={this.addLayer}
          savePointsToLayer={this.savePointsToLayer}
          onFilterChange={(searchFilters) => {
            this.props.mapContext.setMapContext((oldState) => ({
              ...oldState,
              searchFilters,
            }));
          }}
          setShowSearch={(showSearch) => {
            this.props.mapContext.setMapContext((oldState) => ({
              ...oldState,
              showSearch,
            }));
          }}
          fetchDelivery={this.fetchDelivery}
          region={region}
        />

        <AutoSizer style={{ height: "100%", width: "100%" }}>
          {({ width, height }) => (
            <ReactMapGL
              mapboxApiAccessToken={MAPBOX_TOKEN}
              {...viewport}
              width={width}
              ref={this.mapRef}
              height={height}
              onViewportChange={(viewport) => this.updateViewport(viewport)}
              mapStyle={mapStyle}
              attributionControl={false}
              onMouseDown={() =>
                this.props.mapContext.setMapContext((oldState) => ({
                  ...oldState,
                  showSearch: false,
                }))
              }
              onInteractionStateChange={(e) => {
                this.props.mapContext.setMapContext((oldState) => ({
                  ...oldState,
                  inTransition: e.inTransition,
                  isDragging: e.isDragging,
                  isZooming: e.isZooming,
                }));
                setTimeout(() => {
                  this.props.mapContext.setMapContext((oldState) => ({
                    ...oldState,
                    inTransition: false,
                    isDragging: false,
                    isZooming: false,
                  }));
                }, 2000);
                if (!e.inTransition && !e.isDragging && !e.isZooming) {
                  this.updateViewport({
                    ...viewport,
                    transitionDuration: 1000,
                  });
                }
              }}
            >
              <DeckGL initialViewState={viewport}>
                {!!hoverResultItem && (
                  <Marker
                    longitude={hoverResultItem.longitude}
                    latitude={hoverResultItem.latitude}
                    offsetLeft={-15}
                    offsetTop={-30}
                  >
                    <img
                      height={30}
                      width={30}
                      alt="Map highlighted pin"
                      src={PinIcon}
                    />
                  </Marker>
                )}
                {!!hoverInfo?.object && (
                  <Popup
                    longitude={hoverInfo.object.longitude}
                    latitude={hoverInfo.object.latitude}
                    anchor="top-left"
                    closeOnClick={false}
                    style={{ borderRadius: theme.shape.borderRadius }}
                    closeButton={false}
                  >
                    {getStoreName(
                      hoverInfo.object,
                      storeLocatorConfig,
                      orgProperties?.noChainNameReplacement
                    )}
                  </Popup>
                )}
                {layers.map((obj) => obj.component)}
              </DeckGL>

              {!isMobile && (
                <div
                  style={{
                    width: "30px",
                    float: "right",
                    margin: "24px 10px",
                  }}
                >
                  <NavigationControl
                    onViewportChange={(v) => this.updateViewport(v)}
                  />
                </div>
              )}

              {highlight_result?.data && !isMobile && (
                <Popup
                  longitude={highlight_result.data.longitude}
                  latitude={highlight_result.data.latitude}
                  anchor="top-left"
                  closeOnClick={false}
                  style={{
                    borderRadius: theme.shape.borderRadius,
                  }}
                  onClose={() => this.toggleModalPresent()}
                >
                  <div
                    ref={(el) =>
                      el?.addEventListener("wheel", (e) => {
                        e.stopPropagation();
                      })
                    }
                    style={{
                      width: 400,
                      maxHeight: "90vh",
                      overflowY: "auto",
                      position: "relative",
                    }}
                  >
                    <ResultDetails
                      style={{ height: "50px" }}
                      result={highlight_result?.data}
                      storeLocatorConfig={storeLocatorConfig}
                      orgProperties={orgProperties}
                      context={this.context}
                      reviews={highlight_result?.reviews}
                      openInfo={highlight_result?.openInfo}
                    />
                  </div>
                </Popup>
              )}

              {highlight_result?.data && isMobile && (
                <SwipeableDrawer
                  anchor="bottom"
                  open={!!highlight_result?.data}
                  style={{ backgroundColor: "transparent" }}
                  onClose={() => this.toggleModalPresent()}
                  onOpen={() => {}}
                >
                  <Paper style={{ overflow: "hidden", position: "relative" }}>
                    <Box
                      width="100%"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Box></Box>
                      <Box
                        width={30}
                        height={6}
                        backgroundColor={grey[400]}
                        borderRadius={3}
                        marginTop="8px"
                      ></Box>
                      <IconButton
                        aria-label="close-detail"
                        style={{
                          alignSelf: "flex-end",
                          fontWeight: "bold",
                          padding: 0,
                        }}
                        onClick={() => this.toggleModalPresent()}
                        size="medium"
                      >
                        <Close fontSize="inherit" />
                      </IconButton>
                    </Box>
                    <ResultDetails
                      style={{ height: "50px" }}
                      result={highlight_result?.data}
                      storeLocatorConfig={storeLocatorConfig}
                      orgProperties={orgProperties}
                      context={this.context}
                      reviews={highlight_result?.reviews}
                      openInfo={highlight_result?.openInfo}
                    />
                  </Paper>
                </SwipeableDrawer>
              )}

              <div
                style={{
                  position: "absolute",
                  top: "51px",
                  ...(isMobile
                    ? { right: "70px", zIndex: 9 }
                    : { left: "340px" }),
                }}
                onClick={() => {
                  this.props.mapContext.setMapContext((oldState) => ({
                    ...oldState,
                    trackUserLocation: true,
                  }));
                }}
              >
                {geolocateControl}
              </div>
            </ReactMapGL>
          )}
        </AutoSizer>
      </React.Fragment>
    );
  }
}
Map.propTypes = {};
export default withTheme(Map);
