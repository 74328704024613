import _, { capitalize } from "lodash";
import React, { useState } from "react";

const UserContext = React.createContext();

export const RESOURCES = {
  STORES_RANK: 1,
  STORES_GET: 2,
  STORES_CREATE: 3,
  STORES_UPDATE: 39,
  SALES_UPLOAD: 4,
  SALES_PREDICT: 5,
  SALES_UPDATE: 6,
  SALES_DELETE: 7,
  SALES_BTN: 55,
  ANNOTATION_CREATE: 8,
  PRODUCTS_BTN: 41,
  PRODUCTS_CREATE: 9,
  PRODUCTS_UPDATE: 10,
  PRODUCTS_DELETE: 11,
  PRODUCTS_DISCOVER: 12,
  PRODUCTS_COMPARE: 13,
  STORE_PRODUCTS_CREATE: 14,
  STORE_PRODUCTS_UPDATE: 15,
  STORE_PRODUCTS_DELETE: 16,
  GLOBAL_DATERANGE: 17,
  REPORT_MARKETING: 34,
  DOWNLOAD_STORES: 35,
  MARKETING_BTN: 37,
  STORE_LOCATOR_BTN: 38,
  LOCATION_BTN: 40,
  AUDIENCE_SELECT: 21,
  VIEW_CREATE: 22,
  VIEW_UPDATE: 23,
  VIEW_DELETE: 24,
  VIEW_SHARE: 25,
  LAYER_EDIT_CONFIG: 26,
  LAYER_CREATE: 27,
  LAYER_DELETE: 28,
  INTEGRATION_FACEBOOK_CONNECT: 29,
  INTEGRATION_FACEBOOK_DASHBOARD: 30,
  INTEGRATION_GOOGLE_CONNECT: 31,
  INTEGRATION_GOOGLE_DASHBOARD: 32,
  INTEGRATION_SHOPIFY_CONNECT: 54,
  VIEW_MAP_MODAL: 33,
  MANAGE_BILLING: 42,
  MANAGE_USER: 43,
  ROLE_CREATE: 44,
  ROLE_UPDATE: 45,
  ROLE_DELETE: 46,
  STORES_BTN: 47,
  REPORT_ZIPCODE: 48,
  INDIE_INSIGHTS_GET: 49,
  REVIEWS_CREATE: 50,
  USER_CREATE: 51,
  DATA_MORE_INFO: 52,
  MAP_INSIGHTS: 53,
  MANAGE_ORG: 36,
};

export const UserContextProvider = (props) => {
  const [state, setState] = useState({});

  const { children } = props;

  function formatText(t) {
    const caseFromConfig = state?.config?.titleCase || "";
    switch (caseFromConfig) {
      case "camel":
        return _.camelCase(t);
      case "kebab":
        return _.kebabCase(t);
      case "lower":
        return _.lowerCase(t);
      case "snake":
        return _.snakeCase(t);
      case "start":
        return t?.replace(/\w+/g, capitalize) || "";
      case "upper":
        return _.upperCase(t);

      default:
        return t || "";
    }
  }

  function checkAdminPermission(resourceId) {
    return !!state?.adminUser?.resources?.includes(resourceId);
  }
  return (
    <UserContext.Provider
      value={{
        state,
        setState,
        formatText,
        checkAdminPermission,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
