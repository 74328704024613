import { AddLocationAlt, Edit, LocalOffer } from "@mui/icons-material";
import DirectionsIcon from "@mui/icons-material/Directions";
import {
  Box,
  CardContent,
  CardMedia,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { withTheme } from "@mui/styles";
import React, { useContext, useEffect, useState } from "react";
import ReactStars from "react-stars";
import MapContext from "../../Contexts/MapContext";
import UserContext, { RESOURCES } from "../../Contexts/UserContext";
import {
  getStoreName,
  mapsSelector,
  validateSchedule,
} from "../../Utils/Utils";

function ImageSide({ item, storeLocatorConfig, orgProperties }) {
  const mapContext = useContext(MapContext);
  const [imageFaled, setImageFailed] = useState(false);
  const cleanChain = `${item.chain}`.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
  const productImageForChain = mapContext.products?.find(
    (p) =>
      p.chain_images?.[cleanChain] &&
      item.products?.map((sp) => sp.product_id).includes(p.id)
  )?.chain_images?.[cleanChain];
  const imageUrl =
    (storeLocatorConfig.storeCardImage === "product" &&
      (productImageForChain ||
        mapContext.products?.find(
          (p) =>
            p.url && item.products?.map((sp) => sp.product_id).includes(p.id)
        )?.url)) ||
    (storeLocatorConfig.storeCardImage === "static" &&
      storeLocatorConfig.storeCardStaticImage) ||
    (storeLocatorConfig.storeCardImage === "store" && item.image_url) ||
    storeLocatorConfig.storeCardDefaultImage;
  return (
    <>
      {!imageFaled && imageUrl && (
        <CardMedia
          component="img"
          style={{
            width: "100%",
            objectFit: "contain",
            maxHeight: "100%",
            aspectRatio: 1,
          }}
          image={imageUrl}
          title={getStoreName(
            item,
            storeLocatorConfig,
            orgProperties?.noChainNameReplacement
          )}
          onError={() => setImageFailed(true)}
        />
      )}
    </>
  );
}

function AddressSide({ item }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
      onClick={() => mapsSelector(item.latitude, item.longitude)}
    >
      <DirectionsIcon />
      {item.distance && (
        <Typography variant="subtitle2" color="textSecondary">
          {item.distance.toFixed(1)} mi
        </Typography>
      )}
    </div>
  );
}

const sideLookup = {
  image: ImageSide,
  address: AddressSide,
};

function ResultItem({
  storeLocatorConfig,
  index,
  res,
  showResultDetailsPage,
  orgProperties,
}) {
  const userContext = useContext(UserContext);
  const mapContext = useContext(MapContext);
  const [opening_hours, setOpening_hours] = useState(null);
  const [loading_opening_hours, setLoading_opening_hours] = useState(false);

  useEffect(() => {
    const searchInGooglePlaces = async (data, accessToken) => {
      if (res?.json_content?.geolocation?.opening_hours) {
        setOpening_hours(res?.json_content?.geolocation?.opening_hours);
        setLoading_opening_hours(false);
      }
      return;
    };

    searchInGooglePlaces(res.id, userContext.state?.accessToken);
  }, [res, userContext.state]);

  const RightSide = !storeLocatorConfig?.storeCardImage
    ? AddressSide
    : sideLookup[
        ["store", "product", "static"].includes(
          storeLocatorConfig?.storeCardImage
        )
          ? "image"
          : "address"
      ];
  const { hoverInfo, highlight_result, setHoverResultItem } = mapContext;
  const open_now = opening_hours?.open_now;
  const todayValid = validateSchedule(
    res?.start_date,
    res?.end_date,
    res?.recurrence_rule
  );
  const featuredContent =
    !!res?.featured_content &&
    (typeof res?.featured_content === "string"
      ? JSON.parse(res?.featured_content)
      : res?.featured_content || []
    ).filter((fc) => {
      if (fc.startDate || fc.endDate || fc.recurringRule) {
        const todayValid = validateSchedule(
          fc.startDate,
          fc.endDate,
          fc.recurringRule
        );
        return todayValid;
      }
      return true;
    });
  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  const stripAddress = (store) => {
    const { address, city, state, zipcode } = store;
    const addressRegex = new RegExp(`,|${city}|${state}|${zipcode}`, "gi");
    return address.replace(addressRegex, "").replace(/\s+/g, " ").trim();
  };

  return (
    <Paper
      style={{ width: "100%", height: "100%" }}
      key={index}
      elevation={
        (hoverInfo?.object || highlight_result?.data)?.id === res.id ? 3 : 0
      }
      onClick={(event) => {
        showResultDetailsPage?.(res, event, undefined, index, opening_hours);
        if (isMobile) setHoverResultItem(res);
      }}
      onMouseOver={
        !isMobile
          ? () => {
              setHoverResultItem(res);
            }
          : undefined
      }
      onMouseOut={
        !isMobile
          ? () => {
              setHoverResultItem(undefined);
            }
          : undefined
      }
    >
      <CardContent
        style={{
          height: "100%",
          paddingBottom: "16px",
          position: "relative",
        }}
      >
        <Stack
          sx={{ position: "absolute", top: 4, right: 4 }}
          direction={"row"}
        >
          {res?.start_date && res?.end_date && (
            <Tooltip title="Delete">
              <AddLocationAlt
                sx={{ position: "absolute", top: 4, right: 4 }}
                color={todayValid ? "success" : undefined}
              />
            </Tooltip>
          )}
          {userContext.checkAdminPermission(RESOURCES.STORES_UPDATE) && (
            <Tooltip title="Edit store">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  window.parent.postMessage(
                    { action: "edit-store", data: res },
                    "*"
                  );
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
        <Stack
          direction="row"
          width="100%"
          height="100%"
          alignItems="flex-start"
        >
          <Stack
            direction="column"
            style={{
              width:
                storeLocatorConfig?.storeCardImage !== "noimage"
                  ? "70%"
                  : "100%",
            }}
          >
            <Typography
              variant="body2"
              style={{
                lineHeight: "1.5em",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                fontWeight: "bold",
              }}
            >
              {userContext?.formatText(
                getStoreName(
                  res,
                  storeLocatorConfig,
                  orgProperties?.noChainNameReplacement
                )
              )}
            </Typography>
            {storeLocatorConfig?.storeDetails?.showType && (
              <Typography
                variant="caption"
                color="textSecondary"
                style={{
                  lineHeight: "1.5em",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {res.type}
              </Typography>
            )}
            {storeLocatorConfig?.storeDetails?.showAddress && (
              <>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  style={{
                    lineHeight: "1.5em",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {stripAddress(res)}
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  style={{
                    lineHeight: "1.5em",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {[res.city, res.state].filter((c) => !!c).join(", ")}{" "}
                  {res.zipcode || ""}
                </Typography>
              </>
            )}
            <Box height={10}></Box>
            {storeLocatorConfig?.storeDetails?.showReviews &&
              !!storeLocatorConfig?.storeDetails?.numberOfReviewsToShow && (
                <Typography
                  variant="caption"
                  color="textSecondary"
                  style={{
                    display: "block",
                    lineHeight: "1.5em",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                ></Typography>
              )}
            {storeLocatorConfig?.storeDetails?.showReviews && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                <ReactStars
                  count={5}
                  edit={false}
                  value={
                    ((res.yelp_rating || 0) * (res.yelp_review_count || 0) +
                      (res.google_place_rating || 0) *
                        (res.google_place_review_count || 0)) /
                    ((res.yelp_review_count || 0) +
                      (res.google_place_review_count || 0))
                  }
                />
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  style={{ marginLeft: 5 }}
                >
                  (
                  {(res.yelp_review_count || 0) +
                    (res.google_place_review_count || 0)}
                  )
                </Typography>
              </div>
            )}
            <Stack direction="row" spacing={3}>
              {!loading_opening_hours &&
                typeof open_now === "boolean" &&
                storeLocatorConfig?.storeDetails?.openInfo && (
                  <Typography
                    variant="subtitle2"
                    style={{ color: open_now ? "green" : "red" }}
                  >
                    {open_now ? "Open" : "Closed"}
                  </Typography>
                )}
              {loading_opening_hours && (
                <CircularProgress
                  style={{ marginTop: "10px" }}
                  size={24}
                  color={"primary"}
                />
              )}
              {!!featuredContent?.length && (
                <Typography
                  variant="caption"
                  color="green"
                  style={{
                    lineHeight: "1.5em",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  <LocalOffer fontSize="small" />{" "}
                  {featuredContent[0].title
                    ? `Offer: ${featuredContent[0].title}`
                    : ""}
                </Typography>
              )}
            </Stack>
          </Stack>
          {storeLocatorConfig?.storeCardImage !== "noimage" && (
            <div
              style={{
                width: "30%",
                height: "100%",
                overflow: "hidden",
              }}
            >
              <RightSide
                item={res}
                storeLocatorConfig={storeLocatorConfig}
                orgProperties={orgProperties}
              />
            </div>
          )}
        </Stack>
      </CardContent>
    </Paper>
  );
}

export default withTheme(ResultItem);
