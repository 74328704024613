import axiosLibrary from "axios";
import { CurlHelper } from "../Utils/CurlHelper";
import { BASE_URL } from "./Data.service";

const loginMethod = "login";
export const get_users = "api/users";

export const axios = axiosLibrary.create({
  baseURL: BASE_URL,
});

axios.interceptors.response.use(undefined, function (error) {
  let filename = "";
  let curl;
  let curlCommand = "";
  try {
    curl = new CurlHelper(error.config);
    curlCommand = curl.generateCommand();
  } catch (error) {
    curlCommand = `curl ${error}`;
  }
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const config = error.response?.config;
    filename =
      (config?.method || config?.url
        ? `${config?.method} ${config?.url}`
        : JSON.stringify(config || error.response).trim() ||
          "auth.service.js") +
      "\n" +
      curlCommand;
  } else if (error.request) {
    // The request was made but no response was received
    filename =
      "No response received in auth.service.js: " +
      JSON.stringify(error).trim() +
      "\n" +
      curlCommand;
  } else {
    // Something happened in setting up the request that triggered an Error
    filename =
      "Error while making request in auth.service.js: " +
      JSON.stringify(error).trim();
  }
  window.spDathic("trackError", {
    ...error,
    message: `${error.response?.status || ""} ${
      error.message || "No message provided."
    }`,
    filename,
  });
  return Promise.reject(error);
});

export async function loginDathic(username, password) {
  try {
    let url = BASE_URL;
    url = url + loginMethod;
    let params = {
      username,
      password,
    };
    const result = await axios.post(url, params, {
      headers: { "Content-Type": "application/json" },
    });

    return result?.data;
  } catch (error) {
    return { data: { access_token: null } };
  }
}

export async function getUser(userId, accessToken) {
  try {
    let url = BASE_URL;
    url = url + get_users + (userId ? `/${userId}` : "");
    const result = await axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    return undefined;
  }
}
