import { useCallback, useEffect, useRef, useState } from "react";

const usePrevious = (value, initialValue) => {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useEffectDebugger = (
  effectHook,
  dependencies,
  dependencyNames = []
) => {
  const previousDeps = usePrevious(dependencies, []);

  const changedDeps = dependencies.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index;
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency,
        },
      };
    }

    return accum;
  }, {});

  if (Object.keys(changedDeps).length) {
    console.log("[use-effect-debugger] ", changedDeps);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effectHook, dependencies);
};

export const useCallbackDebugger = (
  callbackHook,
  dependencies,
  dependencyNames = []
) => {
  const previousDeps = usePrevious(dependencies, []);

  const changedDeps = dependencies.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index;
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency,
        },
      };
    }

    return accum;
  }, {});

  if (Object.keys(changedDeps).length) {
    console.log("[use-callback-debugger] ", changedDeps);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(callbackHook, dependencies);
};

export const useRetryRequest = (
  request,
  { validateResponse, maxTries = 0 } = {}
) => {
  const [isRequesting, setIsRequesting] = useState(false);
  const [requestSuccess, setRequestSuccess] = useState(false);
  const intervalId = useRef();
  const tryCount = useRef(1);

  const callRequest = useCallback(async () => {
    setIsRequesting(true);
    try {
      const response = await request();
      const isValid = validateResponse
        ? validateResponse(response)
        : !!response;
      if (isValid) {
        if (intervalId.current) clearInterval(intervalId.current);
        setRequestSuccess(true);
        setIsRequesting(false);
      }
    } catch (error) {
      console.error("Request failed, retrying in 10 seconds...", error);
      setRequestSuccess(false);
    } finally {
      setIsRequesting(false);
    }
  }, [request, validateResponse]);

  useEffect(() => {
    if (!requestSuccess) {
      callRequest();
      intervalId.current = setInterval(() => {
        tryCount.current += 1;
        if (maxTries && tryCount.current >= maxTries) {
          clearInterval(intervalId.current); // Stop when maxCalls is reached
        } else {
          callRequest();
        }
      }, 10000); // Retry every 10 seconds

      return () => clearInterval(intervalId.current); // Cleanup the interval on component unmount
    } else if (intervalId.current) {
      clearInterval(intervalId.current);
    }
  }, [maxTries, requestSuccess, callRequest]);

  const retry = useCallback(() => {
    tryCount.current = 1;
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
    setIsRequesting(false);
    setRequestSuccess(false);
  }, []);

  return { isRequesting, requestSuccess, retry };
};
