import { IconLayer, ScatterplotLayer } from "@deck.gl/layers";
import { scaleOrdinal } from "d3-scale";
import { capitalize } from "lodash";
import { DateTime } from "luxon";
import React from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { rrulestr } from "rrule";
import { CA_POS, PUERTO_RICO_POS, US_POS } from "../Views/Home/Map";

export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
}

export function createLayerFromDataset(
  layerInstance,
  toggleModalPresent,
  storeLocatorConfig,
  properties,
  setHoverInfo,
  layerKey
) {
  const colorBasedOnAttribute =
    storeLocatorConfig.mapPins?.type === "basedon" &&
    storeLocatorConfig.mapPins?.basedon_color?.colors?.length;
  let colorScale;
  if (colorBasedOnAttribute) {
    const basedOnValues =
      properties?.find(
        (properties) => properties.name === storeLocatorConfig.mapPins.value
      )?.value || [];
    colorScale = scaleOrdinal()
      .domain(basedOnValues)
      .range(storeLocatorConfig.mapPins.basedon_color.colors);
  }
  return {
    layerInstance: layerInstance,
    component: (storeLocatorConfig.mapPins?.type === "icon" && (
      <IconLayer
        onClick={(e) => {
          setTimeout(() => {
            ShowMessage(e.object, undefined, true, toggleModalPresent, e.index);
          }, 100);
        }}
        onHover={setHoverInfo}
        key={`icon-layer-${layerKey}`}
        id={`icon-layer-${layerKey}`}
        data={layerInstance}
        getIcon={() => ({
          url: storeLocatorConfig.mapPins.value,
          width: 128,
          height: 128,
          anchorY: 128,
        })}
        pickable={true}
        getPosition={(x) => [x.longitude, x.latitude]}
        getSize={() => 2}
        sizeScale={10}
        getColor={() => hexToRgb("#234587")}
      />
    )) || (
      <ScatterplotLayer
        onClick={(e) => {
          ShowMessage(e.object, undefined, true, toggleModalPresent, e.index);
        }}
        onHover={setHoverInfo}
        key={`scatterplot-layer-${layerKey}`}
        id={`scatterplot-layer-${layerKey}`}
        data={layerInstance}
        pickable
        opacity={0.8}
        stroked
        filled
        radiusMinPixels={7}
        radiusMaxPixels={7}
        getPosition={(x) => [x.longitude, x.latitude]}
        getFillColor={(x) => {
          if (colorBasedOnAttribute) {
            return hexToRgb(colorScale(x[storeLocatorConfig.mapPins.value]));
          }
          return hexToRgb(storeLocatorConfig.mapPins?.value || "#234587");
        }}
        getLineColor={[0, 0, 0, 1]}
      />
    ),
  };
}

export function ShowMessage(message, error, modal, toggleModalPresent, index) {
  if (error) {
    window.spDathic("trackError", {
      ...error,
      message: error.message || "No message provided.",
    });
  }
  if (modal) {
    toggleModalPresent(message, null, index);
  } else if (message) {
    confirmAlert({
      title: "Info",
      message: message,
      buttons: [
        {
          label: "OK",
        },
      ],
    });
  } else {
    // Error occured, refresh the page
    console.log("Error occured, refresh the page");
  }
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function mapsSelector(lat, lng) {
  window.open(
    "https://maps.google.com/maps/dir/?api=1&destination=" +
      lat +
      "," +
      lng +
      "&dir_action=navigate"
  );
}

export function windowOpen(url, name, specs) {
  if (!url.match(/^https?:\/\//i)) {
    url = "http://" + url;
  }
  return window.open(url, name, specs);
}

export function encodeToBase64(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

export function decodeFromBase64(str) {
  return decodeURIComponent(escape(window.atob(str)));
}

export const MAX_ZOOM_GPS_LOCATION = 11;
export const MAX_ZOOM_HIGHLIGHTED_ICON = 10;
export const DEFAULT_MAP_ZOOM = 9;
export const MAX_MI = 10;

export function calculateMaxMi(latitude, zoom) {
  return parseInt(
    Math.max(
      MAX_MI,
      (156543.03392 * Math.cos((latitude * Math.PI) / 180)) / Math.pow(2, zoom)
    )
  );
}

export const startCase = (str) => {
  return (
    (typeof str === "string" ? str : "")
      .replace(/\w+/g, capitalize)
      .replace?.(/["_"]/g, " ") ||
    str ||
    ""
  );
};

export const replacePlaceholders = (
  original,
  data,
  noChainNameReplacement,
  fallbackToName
) => {
  let newValue = (typeof original === "string" ? original : "") || "";
  Object.keys(data || {})
    .filter((key) => newValue.includes(`{${key}}`))
    .sort((a, b) => (data[b]?.length || 0) - (data[a]?.length || 0))
    .forEach((key) => {
      const value =
        key === "chain" && !data[key]
          ? noChainNameReplacement ||
            (fallbackToName && data["name"]) ||
            "Independent Store"
          : data[key];
      const existingValueStart = newValue.indexOf(value);
      if (value && existingValueStart > -1) {
        newValue =
          newValue.substring(0, existingValueStart) +
          newValue.substring(existingValueStart + (value?.length || 0));
      }
      newValue = newValue.replace(new RegExp(`{${key}}`, "g"), value || "");
    });
  return newValue;
};

export function getStoreName(store, config, noChainNameReplacement) {
  const nameFormat = config.storeDetails?.nameFormat;
  const fallbackToName = config.storeDetails?.fallbackToName;
  if (!nameFormat) {
    return (
      store.chain || store.name || noChainNameReplacement || "No store name"
    );
  }
  const parsedName = replacePlaceholders(
    nameFormat,
    store,
    noChainNameReplacement,
    fallbackToName
  );
  return parsedName || "No store name";
}

export function sortByCoordinates(results, latitude, longitude) {
  return (results || []).sort((store1, store2) => {
    if (
      !window.turf?.point ||
      isNaN(store1.latitude) ||
      isNaN(store1.longitude) ||
      isNaN(latitude) ||
      isNaN(longitude)
    ) {
      return 0;
    }
    const from1 = window.turf.point([store1.latitude, store1.longitude]);
    const from2 = window.turf.point([store2.latitude, store2.longitude]);
    const to = window.turf.point([latitude, longitude]);
    const distance1 = window.turf.distance(from1, to, { units: "miles" });
    const distance2 = window.turf.distance(from2, to, { units: "miles" });
    return distance1 - distance2;
  });
}

export function validateSchedule(startDate, endDate, recurrence) {
  if (!startDate || !endDate) return false;

  const startDateDateTime = DateTime.fromSQL(startDate);
  const endDateDateTime = DateTime.fromSQL(endDate);
  const isInRange =
    startDateDateTime < DateTime.now() && DateTime.now() < endDateDateTime;
  if (!recurrence || !isInRange) return isInRange;

  const validDate = rrulestr(recurrence).before(
    DateTime.now().toJSDate(),
    true
  );
  return validDate && DateTime.now().hasSame(validDate, "day");
}

export function getInitialCoordinates(config, orgProperties, zoom, region) {
  const country = orgProperties?.country || "US";
  const initialZoom = zoom || config?.initialZoom || DEFAULT_MAP_ZOOM;
  const zoomIsCountry = initialZoom < 5;

  let lat =
    region === "pr"
      ? PUERTO_RICO_POS.lat
      : config?.initialCoordinates?.latitude || 29.617821;
  let lng =
    region === "pr"
      ? PUERTO_RICO_POS.lng
      : config?.initialCoordinates?.longitude || -81.70622;

  if (zoomIsCountry) {
    switch (country) {
      case "US":
        lat = US_POS.lat;
        lng = US_POS.lng;
        break;
      case "CA":
        lat = CA_POS.lat;
        lng = CA_POS.lng;
        break;

      default:
        break;
    }
  }
  return { lat, lng };
}

export function resizeImage(file, maxWidth, maxHeight, callback) {
  const img = new Image();
  img.src = URL.createObjectURL(file);

  img.onload = function () {
    let width = img.width;
    let height = img.height;

    // Calculate the new dimensions while maintaining the aspect ratio
    if (maxWidth && width > maxWidth) {
      height *= maxWidth / width;
      width = maxWidth;
    }

    if (maxHeight && height > maxHeight) {
      width *= maxHeight / height;
      height = maxHeight;
    }

    // Create a canvas element to draw the resized image
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);

    // Convert the canvas to a blob and create a new File
    canvas.toBlob(function (blob) {
      const resizedFile = new File([blob], file.name, {
        type: file.type,
        lastModified: file.lastModified,
      });

      callback(resizedFile);
    }, file.type);
  };
}
