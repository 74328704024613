import { Paper } from "@mui/material";
import React from "react";
import "./styles.css";
import { Bolt } from "@mui/icons-material";

export default function PoweredByDathic() {
  return (
    <Paper
      sx={{
        position: "absolute",
        zIndex: 3,
        padding: "0px 8px",
        top: 0,
        right: 0,
        backgroundColor: "rgba(252,252,252,0.25)",
        color: "#032536",
        fontSize: 11,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Bolt />{" "}
      <a
        id="dathic-powered"
        href="https://dathic.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        by Dathic
      </a>
    </Paper>
  );
}
