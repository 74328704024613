import LocationOnIcon from "@mui/icons-material/LocationOn";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import * as React from "react";
import { useRef } from "react";

const autocompleteService = { current: null };
const sessionToken = { current: null };

export const geocodeByPlaceID = (placeId) => {
  // After the component is mounted it is safe to create a new instance of the Geocoder client.
  // That's because at this point the Google Maps JavaScript API has been loaded. Also if we do it
  // before the component is mounted (i.e. in 'componentWillMount()') we won't be safe to render
  // on the server (SSR) as the 'window' object isn't available.
  const geocoder = new window.google.maps.Geocoder();

  return new Promise((resolve, reject) => {
    geocoder.geocode({ placeId }, (results, status) => {
      if (status !== window.google.maps.GeocoderStatus.OK) {
        reject(
          new Error(
            `Geocoding query for a place with an ID of '${placeId}' failed - response status: ${status}`
          )
        );

        return;
      }

      resolve(results);
    });
  });
};

// Disable the ESLint rule camelcase on the next line as the suggestions returned from the Google
// Maps service have properties that are snake cased
// eslint-disable-next-line camelcase
export const geocodeBySuggestion = ({ place_id }) => geocodeByPlaceID(place_id);

export default function PlacesAutocomplete({
  textFieldProps = {},
  onSuggestionSelected = () => {},
  createAutocompleteRequest = (inputValue) => ({ input: inputValue }),
  onClose,
  onOpen,
  open,
}) {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const typeTimeout = useRef();

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(
          {
            ...request,
            sessionToken: sessionToken.current,
          },
          callback
        );
      }, 200),
    []
  );

  React.useEffect(() => {
    if (
      !autocompleteService.current &&
      window.google?.maps?.places?.AutocompleteSessionToken
    ) {
      sessionToken.current = new window.google.maps.places.AutocompleteSessionToken();
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
  }, []);

  React.useEffect(() => {
    let active = true;
    clearTimeout(typeTimeout.current);

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    typeTimeout.current = setTimeout(() => {
      fetch(createAutocompleteRequest(inputValue), (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      });
    }, 600);

    return () => {
      active = false;
      clearTimeout(typeTimeout.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, inputValue, fetch]);

  const handleOpen = () => {
    if (inputValue.length > 0) {
      onOpen();
    }
  };
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue.length > 0) {
      onOpen();
    } else {
      onClose();
    }
  };

  return (
    <Autocomplete
      id="places-autocomplete"
      sx={{ width: "100%" }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      openOnFocus={false}
      open={open}
      onOpen={handleOpen}
      onClose={onClose}
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        onSuggestionSelected(newValue);
      }}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          inputProps={{
            ...(params?.inputProps || {}),
            ...(textFieldProps?.inputProps || {}),
          }}
          label="Search for a location"
          variant="filled"
          size="small"
          fullWidth
        />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = matches
          ? parse(
              option.structured_formatting.main_text,
              matches.map((match) => [
                match.offset,
                match.offset + match.length,
              ])
            )
          : [];

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: "text.secondary", mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                {!parts.length && (
                  <span
                    style={{
                      fontWeight: 400,
                    }}
                  >
                    {option.structured_formatting.main_text}
                  </span>
                )}
                {!!parts.length &&
                  parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}

                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
