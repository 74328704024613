import {
  Alert,
  Button,
  LinearProgress,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import { withTheme } from "@mui/styles";
import _ from "lodash";
import React, { Component } from "react";
import Geocode from "react-geocode";
import TagManager from "react-gtm-module";
import UserContext from "../../Contexts/UserContext";
import {
  doSearch,
  doSearchStores,
  doSubmitSuggestion,
} from "../../Services/Data.service";
import { ShowMessage } from "../../Utils/Utils";
import PlacesAutocomplete, {
  geocodeBySuggestion,
} from "../Components/PlacesAutocomplete";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);

class SuggestStoreForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coordinates: null,
      loading: false,
      term: "",
      zipcode: null,
      geopoliticalRegion: null,
      name: "",
      email: "",
      description: "",
      chain: "",
      searchOpen: false,
      suggestSuccess: false,
      foundStore: null,
      foundStoreOutside: null,
    };
  }

  onSuggestionSelected = (suggestion) => {
    this.setState({
      term: suggestion.description,
      geopoliticalRegion: suggestion.types[0],
    });
    geocodeBySuggestion(suggestion)
      .then((results) => {
        if (results.length < 1) {
          alert(
            "Geocode request completed successfully but without any results"
          );
        }

        const zipcodeIndex = _.findKey(results[0].address_components, {
          types: ["postal_code"],
        });
        const zipcode = zipcodeIndex
          ? results[0].address_components[zipcodeIndex].long_name
          : "";

        const { geometry } = results[0];

        const coordinates = {
          lat: geometry.location.lat(),
          lng: geometry.location.lng(),
        };

        this.setState({
          coordinates: [coordinates.lat, coordinates.lng],
          zipcode: zipcode,
        });

        TagManager.dataLayer({
          dataLayer: {
            event: "searchChange",
            category: "Search",
            action: "Zipcode (from google places): " + zipcode,
          },
        });
        return doSearchStores(
          [
            {
              address: results?.[0]?.formatted_address,
              zipcode,
              latitude: coordinates.lat,
              longitude: coordinates.lng,
              chain: this.state.chain,
              secondary_images: [],
            },
          ],
          true,
          undefined,
          window.DATHIC_ORG_ID
        );
      })
      .then((searchResult) => {
        if (
          searchResult?.[0]?.store_validation === "found" &&
          searchResult?.[0]?.store_id
        ) {
          this.setState({ foundStore: searchResult?.[0] });
        } else {
          return doSearchStores(
            [
              {
                address: searchResult?.[0]?.address,
                zipcode: searchResult?.[0]?.zipcode,
                latitude: searchResult?.[0]?.latitude,
                longitude: searchResult?.[0]?.longitude,
                chain: searchResult?.[0]?.chain,
                secondary_images: [],
              },
            ],
            false
          );
        }
        return undefined;
      })
      .then((searchResult) => {
        if (
          searchResult?.[0]?.store_validation === "found" &&
          searchResult?.[0]?.store_id
        ) {
          this.setState({ foundStoreOutside: searchResult?.[0] });
        }
      })
      .catch((err) => {
        ShowMessage(undefined, {
          message: err.message,
          filename: "SuggestStoreForm.js:geocodeBySuggestion",
          error: err,
        });
      });
  };

  handleKeyDown = (event) => {
    this.setState({ term: event.target.value });

    if (event.key === "Enter") {
      this.searchWithTerm();
    }
  };

  searchWithTerm = async () => {
    const { term } = this.state;
    if (term === "") {
      ShowMessage("Please enter a valid address or zipcode");
      return;
    }
    TagManager.dataLayer({
      dataLayer: {
        event: "searchChange",
        category: "Search",
        action: "Zipcode or Address:" + term,
      },
    });
    this.setState({ loading: true });

    const data = await Geocode.fromAddress(term);

    this.setState({ loading: false });
    if (data.results[0]) {
      const { geometry } = data.results[0];

      const coordinates = {
        lat: geometry.location.lat,
        lng: geometry.location.lng,
      };

      const zipcodeIndex = _.findKey(data.results[0].address_components, {
        types: ["postal_code"],
      });
      const zipcode = zipcodeIndex
        ? data.results[0].address_components[zipcodeIndex].long_name
        : "";

      this.setState({
        coordinates: [coordinates.lat, coordinates.lng],
        zipcode: zipcode,
      });
    }
  };

  onSubmit = async (e) => {
    const {
      coordinates,
      name,
      email,
      description,
      term,
      chain,
      foundStoreOutside,
    } = this.state;
    if (!coordinates || coordinates.length === 0) {
      alert("Please be more specific with your address");
      return false;
    }
    if (!name) {
      alert("Please enter your name");
      return false;
    }
    if (!email) {
      alert("Please enter your email address");
      return false;
    }

    const suggestion = {
      customer: {
        name: name || undefined,
        email: email || undefined,
      },
      latitude: coordinates?.[0],
      longitude: coordinates?.[1],
      address: term,
      chain,
      description,
      secondary_images: [],
      parent_id: foundStoreOutside?.store_id,
    };

    doSubmitSuggestion(suggestion).then((response) => {
      if (response?.id) {
        const { onSuggest } = this.props;
        this.setState({
          name: "",
          email: "",
          description: "",
          chain: "",
          coordinates: [],
          term: "",
          suggestSuccess: true,
        });
        if (onSuggest) onSuggest();
      }
    });
    return false;
  };

  goToStore(e) {
    const { foundStore } = this.state;
    this.setState({ goingToStore: true });
    doSearch(
      [foundStore.latitude, foundStore.longitude],
      foundStore.zipcode,
      11,
      undefined,
      undefined,
      undefined
    )
      .then((stores) => {
        const store = stores?.find((s) => s.id === foundStore.store_id);
        this.props.showResultDetailsPage(store, e);
      })
      .finally(() => {
        this.setState({ goingToStore: false });
      });
  }

  render() {
    const {
      storeNameReplacement = "Store",
      chainNameReplacement = "Retailer",
    } = this.context?.state?.orgProperties || {};
    const {
      foundStore,
      name,
      email,
      searchOpen,
      term,
      suggestSuccess,
      chain,
      description,
      goingToStore,
    } = this.state;
    return (
      <form id="suggest_store">
        <Stack direction="column" spacing={1}>
          <TextField
            name="name"
            label="Your name"
            variant="filled"
            size="small"
            fullWidth
            value={name}
            onChange={(e) => this.setState({ name: e.target.value })}
            inputProps={{
              style: {
                fontSize: "16px",
              },
            }}
          />
          <TextField
            name="email"
            label="Your email"
            variant="filled"
            size="small"
            fullWidth
            value={email}
            onChange={(e) => this.setState({ email: e.target.value })}
            inputProps={{
              style: {
                fontSize: "16px",
              },
            }}
          />
          <TextField
            name="chain"
            label={chainNameReplacement}
            variant="filled"
            size="small"
            fullWidth
            value={chain}
            onChange={(e) =>
              this.setState({ chain: e.target.value, foundStore: null })
            }
            inputProps={{
              style: {
                fontSize: "16px",
              },
            }}
          />
          <PlacesAutocomplete
            open={searchOpen}
            onOpen={() => this.setState({ searchOpen: true })}
            onClose={() => this.setState({ searchOpen: false })}
            textFieldProps={{
              onKeyDown: (event) => this.handleKeyDown(event),
              onChange: (e) =>
                this.setState({
                  term: (chain || "") + e.target.value,
                  foundStore: null,
                }),
              fullWidth: true,
              margin: "dense",
              value: term,
              placeholder: "Address / Zipcode",
              name: "address",
              inputProps: {
                style: {
                  fontSize: "16px",
                },
              },
            }}
            onSuggestionSelected={(e) => this.onSuggestionSelected(e)}
          />
          <TextField
            name="description"
            label="Any comments?"
            placeholder="Would you like a particular product?"
            variant="filled"
            InputLabelProps={{ shrink: true }}
            size="small"
            fullWidth
            value={description}
            onChange={(e) => this.setState({ description: e.target.value })}
            multiline
            maxRows={4}
            inputProps={{
              style: {
                fontSize: "16px",
              },
            }}
          />
        </Stack>
        {foundStore && (
          <Alert severity="success" style={{ marginTop: 20 }}>
            This store was found!{" "}
            <Button
              variant="contained"
              fullWidth
              onClick={(e) => this.goToStore(e)}
              disabled={!!goingToStore}
            >
              Go to store
            </Button>
            {goingToStore && <LinearProgress />}
          </Alert>
        )}
        <Button
          onClick={this.onSubmit}
          fullWidth
          variant="contained"
          className={this.props.theme.submit}
          style={{ marginTop: 20 }}
          sx={{
            color: this.props.theme.palette.primary.main,
            bgcolor: this.props.theme.palette.secondary.main,
            "&:hover": {
              color: this.props.theme.palette.primary.main,
              bgcolor: this.props.theme.palette.secondary.main,
            },
          }}
        >
          Suggest new {storeNameReplacement}
        </Button>
        <Snackbar
          open={suggestSuccess}
          autoHideDuration={6000}
          onClose={() => this.setState({ suggestSuccess: false })}
        >
          <Alert
            onClose={() => this.setState({ suggestSuccess: false })}
            severity="success"
            sx={{ width: "100%" }}
          >
            Store Suggested
          </Alert>
        </Snackbar>
      </form>
    );
  }
}
SuggestStoreForm.contextType = UserContext;
export default withTheme(SuggestStoreForm);
