import React, { Component } from "react";

import { CssBaseline, ThemeProvider } from "@mui/material";
import HttpsRedirect from "react-https-redirect";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import { MapContextProvider } from "./Contexts/MapContext";
import UserContext, { UserContextProvider } from "./Contexts/UserContext";
import { ErrorBoundary } from "./ErrorBoundary";
import routes from "./Routes/routes";
import { GetTheme } from "./Theme/CustomTheme";

class App extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <HttpsRedirect>
        <ErrorBoundary>
          <UserContextProvider>
            <UserContext.Consumer>
              {({ state, setState }) => (
                <ThemeProvider theme={GetTheme(state?.config)}>
                  <CssBaseline />
                  <MapContextProvider>
                    <HashRouter>
                      <Switch>
                        {routes.map((route, idx) => {
                          return (
                            <Route
                              key={idx}
                              path={route.path}
                              exact={route.exact}
                              name={route.name}
                              render={(props) => <route.component {...props} />}
                            />
                          );
                        })}
                        <Redirect exact from="/" to="/home/us" />
                      </Switch>
                    </HashRouter>
                  </MapContextProvider>
                </ThemeProvider>
              )}
            </UserContext.Consumer>
          </UserContextProvider>
        </ErrorBoundary>
      </HttpsRedirect>
    );
  }
}

export default App;
