import {
  Button,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { withTheme } from "@mui/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactStars from "react-stars";
import { AutoSizer, List } from "react-virtualized";
import { HorizontalBarSeries, LabelSeries, XYPlot, YAxis } from "react-vis";
import "react-vis/dist/style.css";

import Validator from "email-validator";
import { max, mean, round } from "mathjs";
import UserContext from "../../Contexts/UserContext";
import { doGetStoreReviews, doSubmitReview } from "../../Services/Data.service";
import { ShowMessage } from "../../Utils/Utils";

class StoreReviewsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviews: null,
      display_compose: false,
      name: "",
      email: "",
      topic: "",
      comment: "",
      rating: 0,
      loading: false,
      submitReviewResult: null,
    };
    this.rowRenderer = this.rowRenderer.bind(this);
  }

  rowRenderer = ({ index, isScrolling, key, style }) => {
    const reviews = this.state.reviews || [];

    const review = reviews[index];

    return (
      <div key={key} style={style}>
        <Divider />
        <Typography variant="subtitle1" color="textSecondary">
          {review.user_name || "Anonymous user"}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {review.comment}
        </Typography>
        <Typography
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
          }}
          variant="caption"
        >
          <ReactStars count={5} edit={false} value={review.rating} />
        </Typography>
      </div>
    );
  };

  toggleComposeView() {
    if (this.state.display_compose) {
      this.getStore(this.props.store_id, true);
    }
    this.setState({ display_compose: !this.state.display_compose });
  }

  submitReview = async () => {
    try {
      if (
        this.props.store_id === "" ||
        this.state.name === "" ||
        !Validator.validate(this.state.email) ||
        this.state.topic === "" ||
        this.state.comment === "" ||
        this.state.rating === ""
      ) {
        if (!Validator.validate(this.state.email)) {
          ShowMessage("Email is not valid");
        } else {
          ShowMessage("Please enter all fields");
        }

        return;
      }
      this.setState({ loading: true });

      const results = await doSubmitReview(
        this.props.store_id,
        this.state.name,
        this.state.email,
        this.state.topic,
        this.state.comment,
        this.state.rating
      );

      this.setState({ loading: false, submitReviewResult: results });
    } catch (err) {
      ShowMessage(undefined, {
        message: err.message,
        filename: "StoreReviewsComponent.js:submitReview",
        error: err,
      });
      this.setState({ loading: false });
    }
  };

  getStore = async (store_id) => {
    this.setState({ loading: true });
    doGetStoreReviews(store_id)
      .then((result) => {
        this.setState({ reviews: result });
      })
      .catch((error) => {
        ShowMessage(undefined, {
          message: error.message,
          filename: "StoreReviewsComponent.js:doGetStoreReviews",
          error,
        });
      })
      .finally(() => this.setState({ loading: false }));
  };

  componentDidMount() {
    this.getStore(this.props.store_id, true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.store_id !== prevProps.store_id) {
      this.getStore(this.props.store_id, true);
    }

    if (this.state.submitReviewResult && this.state.display_compose) {
      window.spDathic("enableFormTracking");
    }
  }

  setReviewsListContainer(el) {
    el?.addEventListener("wheel", (e) => {
      e.stopPropagation();
    });
  }

  render() {
    //const reviews=this.state.reviews
    const reviews = this.state.reviews || [];
    let chartData = [
      { x: 0, y: "1" },
      { x: 0, y: "2" },
      { x: 0, y: "3" },
      { x: 0, y: "4" },
      { x: 0, y: "5" },
    ];
    const count =
      reviews && reviews.length > 0
        ? _.countBy(_.map(reviews, "rating"), Math.floor)
        : [];

    for (const k in count) {
      if (!isNaN(k)) chartData[k - 1].x = count[k];
    }

    const avg_reviews =
      reviews.length > 0 ? round(mean(_.map(reviews, "rating")), 1) : 0;

    const allowReviews = (this.context || this.props.context).state?.config
      ?.storeDetails?.allowReviewsPublic;

    const submitReviewForm = (
      <form
        id="store-review"
        style={{ paddingRight: 10, maxHeight: "400px", overflowY: "scroll" }}
        noValidate
        autoComplete="off"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <TextField
            name="name"
            style={{ paddingRight: 5 }}
            id="name"
            label="Name"
            value={this.state.name}
            onChange={(e) => this.setState({ name: e.target.value })}
            fullWidth
          />
          <TextField
            name="email"
            id="email"
            label="Email"
            value={this.state.email}
            onChange={(e) => this.setState({ email: e.target.value })}
            fullWidth
          />
        </div>
        <TextField
          name="topic"
          id="topic"
          label="Topic"
          value={this.state.topic}
          onChange={(e) => this.setState({ topic: e.target.value })}
          fullWidth
          margin="normal"
        />
        <TextField
          name="comment"
          id="standard-multiline-flexible"
          label="Comments"
          multiline
          rowsMax="4"
          value={this.state.comment}
          onChange={(e) => this.setState({ comment: e.target.value })}
          fullWidth
          margin="normal"
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ReactStars
            count={5}
            edit={true}
            value={this.state.rating}
            onChange={(val) => this.setState({ rating: val })}
          />
          <div>
            <Button onClick={() => this.toggleComposeView()}>Cancel</Button>
            {this.state.loading ? (
              <CircularProgress size={24} color={"secondary"} />
            ) : (
              <Button color="primary" onClick={() => this.submitReview()}>
                Submit
              </Button>
            )}
          </div>
        </div>
      </form>
    );

    const submitReviewConfirmation = (
      <div
        align="center"
        style={{
          padding: 20,
          color: this.state.submitReviewResult ? "green" : "red",
        }}
      >
        {this.state.submitReviewResult
          ? "Thank you!"
          : "An error occurred, please try again"}
      </div>
    );

    const submitReview = this.state.submitReviewResult
      ? submitReviewConfirmation
      : submitReviewForm;

    return (
      <div>
        {!this.state.display_compose && (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography variant="subtitle1" color="textSecondary">
                {reviews.length} review(s)
              </Typography>
              {reviews.length > 0 && (
                <XYPlot
                  width={250}
                  height={100}
                  yDomain={[1, 5]}
                  xDomain={[0, max(_.map(chartData, "x")) * 1.1]}
                >
                  <YAxis />
                  <HorizontalBarSeries
                    color={"#f4e34c"}
                    data={chartData}
                    animation={{ duration: 1.5 }}
                  />
                  <LabelSeries
                    style={{ fill: "gray", fontSize: "12px" }}
                    data={chartData.map((a) => {
                      return { x: a.x, y: a.y, xOffset: 3, yOffset: 4 };
                    })}
                    labelAnchorX={"middle-alignment"}
                    labelAnchorY={"middle-alignment"}
                    animation={{ duration: 1.5 }}
                    getLabel={(d) =>
                      d.x > 0
                        ? ((d.x * 100) / reviews.length).toFixed(0) + "%"
                        : ""
                    }
                  />
                </XYPlot>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <ReactStars count={5} edit={false} value={avg_reviews} />
              <Typography variant="subtitle2" color="textSecondary">
                {avg_reviews} out of 5.0
              </Typography>
              {allowReviews && (
                <Button
                  size="small"
                  color={"secondary"}
                  onClick={() => this.toggleComposeView()}
                >
                  {this.state.display_compose ? "Show reviews" : "Add review"}
                </Button>
              )}
            </div>
            <Divider />
          </div>
        )}

        {this.state.display_compose ? (
          submitReview
        ) : (
          <div ref={this.setReviewsListContainer}>
            {this.state.loading && (
              <div align="center">
                <CircularProgress size={24} color={"secondary"} />
              </div>
            )}
            <AutoSizer style={{ height: 150 }}>
              {({ height, width }) => {
                return (
                  <List
                    height={height}
                    rowCount={reviews.length}
                    rowHeight={85}
                    rowRenderer={this.rowRenderer}
                    width={width}
                  />
                );
              }}
            </AutoSizer>
          </div>
        )}
      </div>
    );
  }
}

StoreReviewsComponent.propTypes = {
  reviews: PropTypes.array.isRequired,
};

StoreReviewsComponent.contextType = UserContext;
export default withTheme(StoreReviewsComponent);
