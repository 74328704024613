import {
  AddLocationAlt,
  Close,
  CloseRounded,
  Restaurant,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  InputAdornment,
  LinearProgress,
  ListItem,
  Paper,
  Popover,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import _, { debounce, throttle } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Geocode from "react-geocode";
import TagManager from "react-gtm-module";
import MapContext from "../../Contexts/MapContext";
import UserContext from "../../Contexts/UserContext";
import {
  doSearch as doSearchService,
  doSearchStream as doStreamService,
} from "../../Services/Data.service";
import {
  DEFAULT_MAP_ZOOM,
  ShowMessage,
  calculateMaxMi,
  getInitialCoordinates,
  sortByCoordinates,
  startCase,
} from "../../Utils/Utils";
import PlacesAutocomplete, {
  geocodeBySuggestion,
} from "../Components/PlacesAutocomplete";
import BuyOnlineButton from "./BuyOnlineButton";
import ProductFilterContent from "./ProductFilterContent";
import ResultList from "./ResultList";
import SuggestStoreForm from "./SuggestStoreForm";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);

const sortOptions = {
  images: (items) => {
    return _.sortBy(items, ["image_url", "secondary_images"]);
  },
  products: (items) => {
    return _.sortBy(items, ["products"]);
  },
};

export default function SearchPanel({
  addLayer,
  onFilterChange,
  fetchDelivery,
  region,
  setShowSearch,
}) {
  const params = new URLSearchParams(window.location.search);
  const theme = useTheme();

  const isMobile = useMediaQuery("(max-width: 768px)");

  const userContext = useContext(UserContext);
  const mapContext = useContext(MapContext);

  const renderCount = useRef(0);
  renderCount.current = renderCount.current + 1;

  const [selectedSort, setSelectedSort] = useState(
    userContext.state?.config?.defaultSort
  );
  const [mapIsMoving, setIsMoving] = useState(false);
  const [term, setTerm] = useState(params.get("term") || "");
  const [loading, setLoading] = useState(false);
  const [suggestSuccess, setSuggestSuccess] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [searchParams, setSearchParams] = useState({
    coordinates: params.get("coordinates")
      ? params
          .get("coordinates")
          .split(",")
          .map((c) => (!isNaN(c) ? Number(c) : undefined))
          .filter((c) => !!c).length
        ? params
            .get("coordinates")
            .split(",")
            .map((c) => Number(c))
        : undefined
      : undefined,
    zoom: params.get("zoom") ? Number(params.get("zoom")) : undefined,
    _searchParams: {
      preventFly: userContext.state?.config?.fallbackLocationToStores
        ? false
        : true,
      zipcode: params.get("zipcode"),
    },
  });
  const [results, setResults] = useState([]);
  const [filters, setFilters] = useState(
    params.get("filters")
      ? JSON.parse(params.get("filters"))
      : { chain: "", type: "", product: [] }
  );
  const [isSuggestingStore, setIsSuggestingStore] = useState(false);
  const [listUpdates, setListUpdates] = useState(1);
  const [productFilterEl, setProductFilterEl] = React.useState(null);
  const isStrictProductSearch = useRef(false);
  const abortControllerRef = useRef(null);
  const searchId = useRef(null);

  const productFilterOpen = Boolean(productFilterEl);

  const storeLabel =
    userContext.state?.orgProperties?.storeNameReplacement || "Store";
  const storeTypeLabel =
    userContext.state?.orgProperties?.storeTypeNameReplacement || "Type";
  const productLabel =
    userContext.state?.orgProperties?.productNameReplacement || "Product";
  const chainLabel =
    userContext.state?.orgProperties?.chainNameReplacement || "Retailer";

  const search = useCallback(
    async (shouldFlyToCoordinates, zoom, _searchParams) => {
      const newSearchId = new Date().getTime();
      searchId.current = newSearchId;
      const filterResults = (oldResults) =>
        _.reduce(
          _.toPairs(filters),
          (acc, [key, value]) => {
            if (!value) {
              return acc;
            }
            return _.filter(acc, (res) => {
              return key === "product"
                ? !value?.length || isStrictProductSearch.current
                  ? (value || []).every((id) =>
                      (res.products || []).map((p) => p.product_id).includes(id)
                    )
                  : res.products?.find((p) =>
                      (value || []).includes(p.product_id)
                    )
                : key === "chain" && value === "null"
                ? !res.chain
                : key === "type"
                ? !value ||
                  (value.includes("store") && !res.type) ||
                  (res[key] && _.toLower(value).includes(_.toLower(res[key])))
                : res[key] && _.toLower(value).includes(_.toLower(res[key]));
            });
          },
          oldResults
        );

      setLoading(true);
      setResults((oldResults) => {
        const filtered = filterResults(oldResults);
        addLayer(filtered);
        return filtered;
      });
      if (!shouldFlyToCoordinates?.lat || !shouldFlyToCoordinates?.lng) {
        ShowMessage("Please enter a valid address or zipcode");
        setLoading(false);

        return;
      }
      const zip = _searchParams?.zipcode;
      if (!zip && !Object.values(filters || {}).find((v) => !!v?.length)) {
        setSelectedSort(userContext.state?.config?.defaultSort);
      } else {
        setSelectedSort(undefined);
      }
      try {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        const controller = new AbortController();
        abortControllerRef.current = controller;

        let response = await (userContext.state?.config?.useStoreStream
          ? doStreamService
          : doSearchService)(
          [shouldFlyToCoordinates.lat, shouldFlyToCoordinates.lng],
          zip,
          zoom,
          filters.chain,
          filters.product?.length
            ? typeof filters.product === "string"
              ? [filters.product]
              : filters.product
            : [],
          undefined,
          false,
          controller
        );

        const parseResponse = async (response, preventFly) => {
          if (!userContext.state?.config?.useStoreStream) {
            return response;
          }
          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          let result = "";
          let results = [];
          const throttleAddLayer = throttle(addLayer, 100);
          let parsedFirstItem = false;

          try {
            let readResult;
            while (!(readResult = await reader.read()).done) {
              result += decoder.decode(readResult.value, { stream: true });
              const lines = result.split("\n");
              result = lines.pop() || "";

              for (const line of lines) {
                results.push(JSON.parse(line));
                let _shouldFlyToCoordinates =
                  params.get("zipcode") &&
                  _searchParams.searchedFirstTime === false
                    ? results?.[0]
                      ? { lat: results[0].latitude, lng: results[0].longitude }
                      : shouldFlyToCoordinates
                    : shouldFlyToCoordinates;
                if (newSearchId < searchId.current) {
                  return;
                }
                throttleAddLayer(
                  results,
                  parsedFirstItem || preventFly
                    ? false
                    : _shouldFlyToCoordinates,
                  _searchParams?.geopoliticalRegion
                );
                setResults(results);
                if (!parsedFirstItem) {
                  parsedFirstItem = true;
                }
              }
            }
          } catch (error) {
            console.log(error);
          }
          return results;
        };

        let preventFly =
          (!params.get("zipcode") ||
            _searchParams.searchedFirstTime === true) &&
          !!_searchParams.preventFly;
        let results = await parseResponse(response, preventFly);

        const firstResultLength = results?.length;
        const fallbackLocationToStores =
          userContext.state?.config?.fallbackLocationToStores ||
          params.get("zipcode");
        if (
          (params.get("zipcode") || !firstResultLength) &&
          fallbackLocationToStores &&
          _searchParams.searchedFirstTime === false
        ) {
          const controller = new AbortController();
          abortControllerRef.current = controller;
          response = await (userContext.state?.config?.useStoreStream
            ? doStreamService
            : doSearchService)(
            [shouldFlyToCoordinates.lat, shouldFlyToCoordinates.lng],
            zip,
            zoom,
            filters.chain,
            filters.product?.length
              ? typeof filters.product === "string"
                ? [filters.product]
                : filters.product
              : [],
            undefined,
            true,
            controller
          );
          preventFly =
            ((!params.get("zipcode") && !!firstResultLength) ||
              !fallbackLocationToStores ||
              _searchParams.searchedFirstTime === true) &&
            !!_searchParams.preventFly;
          results = await parseResponse(response, preventFly);
        }
        if (newSearchId < searchId.current) {
          return;
        }
        let _shouldFlyToCoordinates =
          fallbackLocationToStores &&
          (params.get("zipcode") || !firstResultLength) &&
          _searchParams.searchedFirstTime === false
            ? results?.[0]
              ? { lat: results[0].latitude, lng: results[0].longitude }
              : shouldFlyToCoordinates
            : shouldFlyToCoordinates;
        fetchDelivery([
          _shouldFlyToCoordinates.lat,
          _shouldFlyToCoordinates.lng,
        ]);

        const filteredResults = filterResults(results);
        TagManager.dataLayer({
          dataLayer: {
            event: "searchChange",
            category: "Search",
            action: _searchParams?.term,
            snowplowSearchFilters: Object.entries({
              ...filters,
              coordinates: _shouldFlyToCoordinates,
              zip,
              zoom,
            }).reduce(
              (acc, [key, value]) => ({ ...acc, [key]: JSON.stringify(value) }),
              {}
            ),
            snowplowSearchTotal: results?.length || 0,
            snowplowSearchPage: filteredResults?.length || 0,
          },
        });
        preventFly =
          ((!params.get("zipcode") && !!firstResultLength) ||
            !fallbackLocationToStores ||
            _searchParams.searchedFirstTime === true) &&
          !!_searchParams.preventFly;
        addLayer(
          filteredResults,
          preventFly ? false : _shouldFlyToCoordinates,
          _searchParams?.geopoliticalRegion
        );
        setResults(filteredResults);
        setLoading(false);
        setShowSearch(false);
        setIsSuggestingStore(false);
      } catch (error) {
        ShowMessage(undefined, {
          message: "" + error.message,
          filename: "SearchPanel.js:search",
          error,
        });
        setLoading(false);
      }
    },
    [addLayer, filters, userContext.state]
  );
  const searchWithTerm = useCallback(
    async (termArg) => {
      const _term = termArg || term;
      if (_term === "") {
        ShowMessage("Please enter a valid address or zipcode");
        return;
      }
      setLoading(true);

      let data;
      try {
        data = await Geocode.fromAddress(_term);
      } catch (error) {
        console.log("Geocode no results");
      }

      setLoading(false);
      if (data?.results?.[0]) {
        const { geometry } = data?.results[0];
        const geopoliticalRegion = [
          "street_address",
          "locality",
          "postal_code",
          "country",
        ].includes(data?.results[0].types[0])
          ? data?.results[0].types[0]
          : "street_address";
        let zoom = undefined;
        switch (geopoliticalRegion) {
          case "street_address":
            zoom = DEFAULT_MAP_ZOOM + 4;
            break;
          case "locality":
            zoom = DEFAULT_MAP_ZOOM + 2;
            break;
          case "postal_code":
            zoom = DEFAULT_MAP_ZOOM + 4;
            break;
          case "country":
            zoom = DEFAULT_MAP_ZOOM - 4;
            break;
          default:
            break;
        }
        setSearchParams((oldParams) => ({
          coordinates: [geometry.location.lat, geometry.location.lng],
          zoom: zoom || oldParams.zoom,
          _searchParams: {
            searchedFirstTime:
              oldParams?._searchParams?.searchedFirstTime === undefined
                ? false
                : true,
            zipcode: _term,
            geopoliticalRegion,
            term: _term,
          },
        }));
      } else {
        setLoading(true);
        doSearchService(
          undefined,
          undefined,
          undefined,
          _term,
          undefined,
          undefined,
          false,
          undefined,
          1
        )
          .then((stores) => {
            if (stores?.[0]?.address) {
              searchWithTerm(stores[0].address);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [term]
  );

  const handleKeyDown = (event) => {
    setTerm(event.target.value || "");

    if (event.key === "Enter") {
      setSearchOpen(false);
      searchWithTerm();
    }
  };

  const onSuggestionSelected = (suggestion) => {
    setTerm(suggestion.description);
    geocodeBySuggestion(suggestion)
      .then((results) => {
        if (results.length < 1) {
          alert(
            "Geocode request completed successfully but without any results"
          );
        }

        const zipcodeIndex = _.findKey(results[0].address_components, {
          types: ["postal_code"],
        });
        const zipcode =
          zipcodeIndex > -1
            ? results[0].address_components[zipcodeIndex].long_name
            : "";

        const { geometry } = results[0];
        const geopoliticalRegion = [
          "street_address",
          "locality",
          "postal_code",
          "country",
        ].includes(suggestion.types[0])
          ? suggestion.types[0]
          : "street_address";
        let zoom = undefined;
        switch (geopoliticalRegion) {
          case "street_address":
            zoom = DEFAULT_MAP_ZOOM + 4;
            break;
          case "locality":
            zoom = DEFAULT_MAP_ZOOM + 2;
            break;
          case "postal_code":
            zoom = DEFAULT_MAP_ZOOM + 4;
            break;
          case "country":
            zoom = DEFAULT_MAP_ZOOM - 4;
            break;
          default:
            break;
        }

        setSearchParams((oldParams) => ({
          coordinates: [geometry.location.lat(), geometry.location.lng()],
          zoom: zoom || oldParams.zoom,
          _searchParams: {
            searchedFirstTime:
              oldParams?._searchParams?.searchedFirstTime === undefined
                ? false
                : true,
            zipcode,
            geopoliticalRegion,
            term: suggestion.description,
          },
        }));
      })
      .catch((err) => {
        ShowMessage(undefined, {
          message: err.message,
          filename: "SearchPanel.js:geocodeBySuggestion",
          error: err,
        });
      });
  };

  const doReset = () => {
    setLoading(false);
    setResults([]);
    mapContext.toggleModalPresent?.();
    setTerm("");
  };

  const showResultDetailsPage = (res, event, reviews, index, openInfo) => {
    mapContext.toggleModalPresent?.(res, reviews, index, openInfo);
  };

  const filterOptions = useMemo(() => {
    const getFilterList = {
      Product: (mapContext.products || []).reduce(
        (acc, p) => acc.set(p.id, startCase(_.toLower(p.name))),
        new Map()
      ),
      Chain: (
        userContext.state?.properties?.find(
          (properties) => properties.name === "chain"
        )?.value || []
      ).reduce((acc, p) => acc.set(p, startCase(_.toLower(p))), new Map()),
      Type: (
        userContext.state?.properties?.find(
          (properties) => properties.name === "type"
        )?.value || []
      ).reduce((acc, p) => acc.set(p, startCase(_.toLower(p))), new Map()),
    };
    return (userContext.state?.config?.searchFilters || []).reduce(
      (acc, filterType) => ({
        ...acc,
        [filterType]: [
          ["", "All"],
          ...[
            filterType === "Chain"
              ? [
                  "null",
                  userContext.state?.orgProperties?.noChainNameReplacement ||
                    "Independent Stores",
                ]
              : [],
          ],
          ...Array.from(getFilterList[filterType].entries())
            .map((entry) => [
              ...entry,
              _.filter(results, (res) =>
                filterType.toLowerCase() === "product"
                  ? res.products?.find((p) => p.product_id === entry[0])
                  : _.toLower(res[filterType.toLowerCase()]) ===
                    _.toLower(entry[0])
              )?.length,
            ])
            .sort(
              (a, b) => b[2] - a[2] || _.toLower(a).localeCompare(_.toLower(b))
            ),
        ],
      }),
      {}
    );
  }, [mapContext.products, results, userContext.state]);

  // Listen to changes in viewport to check whether a new search should be made
  // New search if:
  // - map not transitioning AND (user is zooming out OR moving past last search distance)
  useEffect(() => {
    const updateMapViewWithCoordinates = async (pos) => {
      let coordinates = [];
      if (pos.coords) {
        coordinates = [pos.coords.latitude, pos.coords.longitude];
        setSearchParams((oldParams) => ({
          ...oldParams,
          coordinates,
          zoom: mapContext.viewport.zoom,
          _searchParams: {
            searchedFirstTime:
              oldParams?._searchParams?.searchedFirstTime === undefined
                ? false
                : true,
            preventFly: true,
          },
        }));
      }
    };

    if (
      searchParams.coordinates &&
      window.turf?.point &&
      window.turf?.distance &&
      !isNaN(searchParams.coordinates[0]) &&
      !isNaN(searchParams.coordinates[1]) &&
      !isNaN(mapContext.viewport.latitude) &&
      !isNaN(mapContext.viewport.longitude)
    ) {
      const from = window.turf.point(searchParams.coordinates);
      const to = window.turf.point([
        mapContext.viewport.latitude,
        mapContext.viewport.longitude,
      ]);
      const distance = window.turf.distance(from, to, { units: "miles" });
      const searchDistance = calculateMaxMi(
        searchParams.coordinates[0],
        searchParams.zoom
      );

      const isZoomingOut =
        mapContext.isZooming &&
        searchParams.zoom &&
        searchParams.zoom - mapContext.viewport.zoom >= 1;
      const movingPastSearchDistance =
        distance > searchDistance && (term === "" || mapContext.isDragging);
      if (
        !mapContext.inTransition &&
        (isZoomingOut || movingPastSearchDistance)
      ) {
        updateMapViewWithCoordinates({
          coords: {
            latitude: mapContext.viewport.latitude,
            longitude: mapContext.viewport.longitude,
          },
        });
      }
    } else {
      updateMapViewWithCoordinates({
        coords: {
          latitude: mapContext.viewport.latitude,
          longitude: mapContext.viewport.longitude,
        },
      });
    }

    if (
      !mapContext.inTransition &&
      !mapContext.isDragging &&
      !mapContext.isZooming
    ) {
      setResults((oldResults) => {
        const newResults = selectedSort
          ? sortOptions[selectedSort](oldResults)
          : sortByCoordinates(
              oldResults,
              mapContext.viewport.latitude,
              mapContext.viewport.longitude
            );
        addLayer(newResults, false);
        return newResults;
      });
      setListUpdates((oldUpdate) => oldUpdate + 1);
    }
  }, [
    selectedSort,
    searchParams,
    mapContext.inTransition,
    mapContext.isDragging,
    mapContext.isZooming,
    mapContext.viewport.latitude,
    mapContext.viewport.longitude,
    mapContext.viewport.zoom,
    term,
  ]);

  useEffect(() => {
    if (renderCount.current === 7) {
      const params = new URLSearchParams(window.location.search);
      const paramCoordinates = params
        .get("coordinates")
        ?.split(",")
        .map((c) => Number(c));
      const initialCoordinates =
        paramCoordinates?.length === 2
          ? { lat: paramCoordinates[0], lng: paramCoordinates[1] }
          : getInitialCoordinates(
              userContext.state?.config,
              userContext.state?.orgProperties,
              searchParams.zoom,
              region
            );
      mapContext.updateViewport?.({
        ...mapContext.viewport,
        latitude: initialCoordinates.lat,
        longitude: initialCoordinates.lng,
        zoom: searchParams.zoom,
      });
    }
  }, [mapContext, userContext.state, searchParams.zoom]);

  useEffect(() => {
    const { coordinates, zoom, _searchParams } = searchParams;
    if (coordinates?.length === 2 && zoom) {
      const params = new URLSearchParams(window.location.search);
      if (params.get("zipcode")) {
        searchWithTerm(params.get("zipcode"));
        const url = new URL(window.location);
        url.searchParams.delete("coordinates");
        url.searchParams.delete("zipcode");
        window.history.pushState({}, "", "?" + url.searchParams.toString());
        return;
      }
      search({ lat: coordinates[0], lng: coordinates[1] }, zoom, _searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, search]);

  useEffect(() => {
    if (!mapContext.deliveryInventory) {
      setTabIndex(0);
    }
  }, [mapContext.deliveryInventory]);

  if (mapContext.trackUserLocation && term !== "") {
    setTerm("");
  }

  const getFilterTypeName = (filterType) => {
    switch ((filterType || "").toLowerCase()) {
      case "type":
        return storeTypeLabel;
      case "product":
        return productLabel;
      case "chain":
        return chainLabel;

      default:
        return filterType;
    }
  };

  const StoreResultsTabs = () => (
    <Stack direction={"row"}>
      <Button
        variant="text"
        sx={{
          color:
            tabIndex === 0
              ? theme.palette.secondary.main
              : theme.palette.primary.contrastText,
          flex: 1,
          borderBottom: tabIndex === 0 ? "2px solid" : "none",
          borderRadius: 0,
        }}
        onClick={() => setTabIndex(0)}
      >
        Buy in store
      </Button>
      <Button
        sx={{
          color:
            tabIndex === 1
              ? theme.palette.secondary.main
              : theme.palette.primary.contrastText,
          borderBottom: tabIndex === 1 ? "2px solid" : "none",
          borderRadius: 0,
          flex: 1,
        }}
        onClick={() => setTabIndex(1)}
        disabled={!mapContext.deliveryInventory}
        id="map-buy-online"
      >
        <>
          {userContext.state?.config?.buyOnlineButtonText || "Buy Online"}
          {!mapContext.deliveryInventory && (
            <CircularProgress
              style={{ position: "absolute", right: 0, top: 15 }}
              color="secondary"
              size={18}
            />
          )}
        </>
      </Button>
    </Stack>
  );

  useEffect(() => {
    const delayedFunction = debounce(() => {
      setIsMoving(false);
    }, 500);

    if (
      !mapContext.isZooming &&
      !mapContext.isDragging &&
      !mapContext.inTransition
    ) {
      delayedFunction();
    } else {
      setIsMoving(true);
    }

    return () => {
      delayedFunction.cancel();
    };
  }, [mapContext.isZooming, mapContext.isDragging, mapContext.inTransition]);

  const searchPanelStack = () => {
    const getTypeIcon = (type) => {
      switch (type) {
        case "store":
          return <ShoppingCartOutlined size={36} color="primary" />;
        case "popup_store":
          return <AddLocationAlt size={36} color="primary" />;
        default:
          return <Restaurant size={36} color="primary" />;
      }
    };
    const productFilter =
      filters.product && typeof filters.product === "string"
        ? [+filters.product]
        : filters.product || [];
    const productFilterValue = productFilter.length
      ? productFilter.length === 1
        ? mapContext.products?.find((p) => productFilter[0] === p.id).name
        : `${productFilter.length} selected`
      : "";

    const onSelectedProductsChange = (ids, strictSelection) => {
      isStrictProductSearch.current = !!strictSelection;
      setFilters((f) => ({ ...f, product: ids }));
      onFilterChange?.({
        ...filters,
        product: ids,
      });
      setProductFilterEl(null);
      ids.forEach((id) => {
        TagManager.dataLayer({
          dataLayer: {
            event: "flavorFilterChange",
            category: "Product Filter",
            label: id,
            data: mapContext.products.find((p) => id === p.id)?.name,
            snowplowParameters: {
              terms: [
                `${id}`,
                `${mapContext.products.find((p) => id === p.id)?.name}`,
              ],
              filters: {
                "Product-Filter": `${id}`,
              },
            },
          },
        });
      });
    };
    return (
      <Stack direction="column" spacing="10px" height="100%">
        {!!mapIsMoving && <LinearProgress />}
        {!mapIsMoving && !isSuggestingStore && (
          <form id="search-panel" onSubmit={(e) => e.preventDefault()}>
            <Stack direction="column" spacing="10px">
              <Stack direction="row">
                <PlacesAutocomplete
                  open={searchOpen}
                  onOpen={() => setSearchOpen(true)}
                  onClose={() => setSearchOpen(false)}
                  textFieldProps={{
                    onKeyDown: (event) => handleKeyDown(event),
                    onChange: (e) => {
                      setTerm(e.target.value || "");
                    },
                    onFocus: () => {
                      setShowSearch(true);
                    },
                    fullWidth: true,
                    margin: "dense",
                    value: term,
                    placeholder: "Address / Zipcode",
                    type: "search",
                    name: "address",
                    inputProps: {
                      value: term,
                      style: {
                        fontSize: "16px",
                      },
                    },
                  }}
                  onSuggestionSelected={(e) => {
                    if (e) {
                      onSuggestionSelected(e);
                    } else {
                      doReset();
                    }
                  }}
                  createAutocompleteRequest={(inputValue) => {
                    const isSearchingAddress = /\d+.*[A-z]/.exec(inputValue);
                    return {
                      input: inputValue,
                      types: [isSearchingAddress ? "geocode" : "(regions)"],
                    };
                  }}
                />

                {isMobile && <div style={{ width: 50 }}></div>}
              </Stack>
              <Stack direction="row" spacing="10px" width="100%">
                {userContext.state?.config?.searchFilters?.includes(
                  "Product"
                ) && (
                  <>
                    <TextField
                      label={getFilterTypeName("Product") + "s"}
                      variant="filled"
                      fullWidth
                      size="small"
                      name={`filter-Product`}
                      onClick={(event) => {
                        setProductFilterEl(event.currentTarget);
                      }}
                      value={productFilterValue}
                      focused={productFilterOpen}
                      inputProps={{
                        style: {
                          fontSize: "16px",
                        },
                      }}
                      InputProps={{
                        endAdornment:
                          productFilterValue === "" ? undefined : (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="clear product filter"
                                edge="end"
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onSelectedProductsChange([], false);
                                }}
                              >
                                <CloseRounded />
                              </IconButton>
                            </InputAdornment>
                          ),
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: "0.75rem",
                          textOverflow: "ellipsis",
                        },
                        "& .MuiInputBase-root": {
                          height: "100%",
                        },
                      }}
                    />
                    <Popover
                      open={productFilterOpen}
                      anchorEl={productFilterEl}
                      onClose={() => {
                        setProductFilterEl(null);
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <ProductFilterContent
                        selectedProducts={
                          filters.product && typeof filters.product === "string"
                            ? [+filters.product]
                            : filters.product
                        }
                        isStrictSelection={isStrictProductSearch.current}
                        setSelectedProducts={onSelectedProductsChange}
                      />
                    </Popover>
                  </>
                )}
                {userContext.state?.config?.searchFilters
                  ?.filter((f) => f !== "Product")
                  .map((filterType) => (
                    <Autocomplete
                      key={filterType}
                      isOptionEqualToValue={(op, val) => true}
                      sx={{ width: "100%" }}
                      options={filterOptions[filterType]}
                      getOptionLabel={(option) =>
                        `${startCase(_.toLower(option[1]))} ${
                          option[2] &&
                          userContext.state?.config?.showOptionCount
                            ? `(${option[2]})`
                            : ""
                        }`
                      }
                      onChange={(event, option) => {
                        const value = option?.[0] || "";
                        const label = option?.[1] || "";
                        setFilters({
                          ...filters,
                          [filterType.toLowerCase()]: value,
                        });
                        onFilterChange?.({
                          ...filters,
                          [filterType.toLowerCase()]: value,
                        });
                        TagManager.dataLayer({
                          dataLayer: {
                            event:
                              filterType === "Chain"
                                ? "chainFilterChange"
                                : filterType === "Product"
                                ? "flavorFilterChange"
                                : "typeFilterChange",
                            category: filterType + " Filter",
                            label: value,
                            data: label,
                            snowplowParameters: {
                              terms: [`${value}`, `${label}`],
                              filters: {
                                [filterType + "-Filter"]: `${value}`,
                              },
                            },
                          },
                        });
                      }}
                      autoComplete
                      includeInputInList
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={getFilterTypeName(filterType)}
                          variant="filled"
                          fullWidth
                          size="small"
                          name={`filter-${filterType}`}
                          sx={{
                            "& .MuiInputBase-input": {
                              fontSize: "16px",
                            },
                            "& .MuiInputBase-root": {
                              height: "100%",
                            },
                          }}
                        />
                      )}
                      renderOption={(props, option, state, ownerState) => (
                        <ListItem {...props} dense sx={{ fontSize: 14 }}>
                          {ownerState.getOptionLabel
                            ? ownerState.getOptionLabel(option)
                            : option}
                        </ListItem>
                      )}
                    />
                  ))}
              </Stack>
            </Stack>
          </form>
        )}

        {!isSuggestingStore &&
          !mapIsMoving &&
          !!userContext.state?.config?.showBuyOnlineButton && (
            <StoreResultsTabs />
          )}
        {!isMobile && !isSuggestingStore && tabIndex === 0 && (
          <Stack direction="column" spacing={1} style={{ flex: 1 }}>
            {!!userContext.state?.config?.searchTypes?.length && (
              <Stack
                direction="row"
                width="100%"
                alignItems="flex-start"
                justifyContent="flex-start"
                spacing={1}
              >
                {["store", ...userContext.state.config.searchTypes].map(
                  (type) => (
                    <Stack
                      key={type}
                      direction="column"
                      spacing={0}
                      alignItems="center"
                      onClick={
                        _.toLower(filters.type || "").includes(_.toLower(type))
                          ? undefined
                          : () => {
                              setFilters((oldFilters) => ({
                                ...oldFilters,
                                type: [
                                  ...((oldFilters.type || "").split(",")[0]
                                    ? (oldFilters.type || "").split(",")
                                    : []),
                                  type,
                                ].join(","),
                              }));
                              onFilterChange?.({
                                ...filters,
                                type: [
                                  ...((filters.type || "").split(",")[0]
                                    ? (filters.type || "").split(",")
                                    : []),
                                  type,
                                ].join(","),
                              });
                            }
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        style={{
                          borderRadius: 200,
                          padding: 3,
                          border: `1px solid ${
                            _.toLower(filters.type || "").includes(
                              _.toLower(type)
                            )
                              ? theme.palette.primary.contrastText
                              : "transparent"
                          }`,
                          position: "relative",
                        }}
                      >
                        {_.toLower(filters.type || "").includes(
                          _.toLower(type)
                        ) && (
                          <IconButton
                            aria-label="remove type filter"
                            style={{
                              borderRadius: 200,
                              position: "absolute",
                              top: -5,
                              right: -5,
                              backgroundColor:
                                theme.palette.primary.contrastText,
                              padding: 2,
                            }}
                            size="small"
                            onClick={() => {
                              setFilters((oldFilters) => ({
                                ...oldFilters,
                                type: (oldFilters.type || "")
                                  .split(",")
                                  .filter((t) => t !== type)
                                  .join(","),
                              }));
                              onFilterChange?.({
                                ...filters,
                                type: (filters.type || "")
                                  .split(",")
                                  .filter((t) => t !== type)
                                  .join(","),
                              });
                            }}
                          >
                            <Close fontSize="inherit" />
                          </IconButton>
                        )}
                        <div
                          style={{
                            borderRadius: 200,
                            backgroundColor: theme.palette.secondary.main,
                            padding: 3,
                            height: "2em",
                            width: "2em",
                          }}
                        >
                          {getTypeIcon(type)}
                        </div>
                      </div>
                      <Typography
                        color={theme.palette.primary.contrastText}
                        style={{ fontSize: 10 }}
                      >
                        {startCase(type)}
                      </Typography>
                    </Stack>
                  )
                )}
              </Stack>
            )}
            {loading && (
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  color: theme.palette.primary.contrastText,
                }}
              >
                <Stack>
                  <Typography>Searching for {storeLabel}...</Typography>
                  <LinearProgress />
                </Stack>
              </div>
            )}
            {!results?.length && !loading && (
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  color: theme.palette.primary.contrastText,
                }}
              >
                <Stack alignItems="center" style={{ textAlign: "center" }}>
                  <Typography>
                    No {storeLabel} in this area for your search
                  </Typography>
                  <Typography>
                    try changing search filters or location
                  </Typography>
                </Stack>
              </div>
            )}
            {!!results?.length &&
              !loading &&
              !userContext.state.config?.hideAvailabilityMessage && (
                <Typography variant="subtitle2">
                  Always check availability with store
                </Typography>
              )}
            {!!results?.length &&
              (!loading || !!userContext.state.config?.useStoreStream) &&
              !mapIsMoving && (
                <div style={{ flex: 1 }}>
                  <ResultList
                    key={listUpdates}
                    data={
                      selectedSort
                        ? sortOptions[selectedSort](results)
                        : results
                    }
                    term={term}
                    showResultDetailsPage={showResultDetailsPage}
                  />
                </div>
              )}
          </Stack>
        )}
        {!isMobile &&
          !isSuggestingStore &&
          tabIndex === 1 &&
          userContext.state?.config?.showBuyOnlineButton && (
            <BuyOnlineButton products={mapContext.products} />
          )}
        {userContext.state?.config?.allowSuggestions && (
          <Stack>
            {!isSuggestingStore && !mapIsMoving && (
              <Button
                variant="text"
                color="inherit"
                onClick={() =>
                  userContext.state?.config?.suggestForm
                    ? window.open(
                        userContext.state.config.suggestForm,
                        "_blank"
                      )
                    : setIsSuggestingStore(true)
                }
              >
                Suggest {storeLabel}
              </Button>
            )}
            {isSuggestingStore && (
              <>
                <div
                  style={{
                    padding: 10,
                  }}
                >
                  <span>
                    You can suggest a {storeLabel} you want our {productLabel}s
                    in by filling out this form
                  </span>
                  <SuggestStoreForm
                    onSuggest={() => {
                      setSuggestSuccess(true);
                      setIsSuggestingStore(false);
                    }}
                    showResultDetailsPage={showResultDetailsPage}
                  />
                </div>
                <Button
                  variant="text"
                  color="inherit"
                  onClick={() => setIsSuggestingStore(false)}
                >
                  Cancel
                </Button>
              </>
            )}
            <Snackbar
              open={suggestSuccess}
              autoHideDuration={6000}
              onClose={() => setSuggestSuccess(false)}
            >
              <Alert
                onClose={() => setSuggestSuccess(false)}
                severity="success"
                sx={{ width: "100%" }}
              >
                Store Suggested
              </Alert>
            </Snackbar>
          </Stack>
        )}
      </Stack>
    );
  };
  return (
    <>
      <Paper
        key={window.innerHeight}
        elevation={0}
        style={{
          margin: "35px 26px",
          padding: 10,
          background: theme.palette.primary.main,
          width: isMobile ? null : 300,
          height: isMobile ? undefined : "80vh",
          position: "absolute",
          left: 0,
          right: isMobile ? 0 : null,
          zIndex: 1,
          color: theme.palette.primary.contrastText,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {!isMobile && searchPanelStack()}
        {!!isMobile && (
          <>
            <Collapse
              in={mapContext.showSearch}
              collapsedSize={mapIsMoving ? 30 : 50}
              timeout="auto"
            >
              {searchPanelStack()}
            </Collapse>
            {!mapContext.showSearch && !mapIsMoving && <StoreResultsTabs />}
          </>
        )}
        {!!isMobile && loading && (
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              color: theme.palette.primary.contrastText,
            }}
          >
            <Stack>
              <Typography>Searching for {storeLabel}...</Typography>
              <LinearProgress />
            </Stack>
          </div>
        )}
      </Paper>
      {results && isMobile && !isSuggestingStore && (
        <div
          style={{
            display: mapContext.highlight_result?.data ? "none" : "",
            width: "100%",
            position: "absolute",
            bottom: 0,
            zIndex: 1,
          }}
        >
          {tabIndex === 0 && !!results?.length && !mapIsMoving && (
            <ResultList
              key={listUpdates}
              data={results}
              showResultDetailsPage={showResultDetailsPage}
            />
          )}
          {tabIndex === 1 && userContext.state?.config?.showBuyOnlineButton && (
            <BuyOnlineButton products={mapContext.products} />
          )}
          {!loading && tabIndex === 0 && results && results?.length === 0 && (
            <div
              style={{
                position: "relative",
                zIndex: 1000,
                backgroundColor: "white",
                overflowY: "hidden",
                width: "100%",
                height: 160,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: 10,
              }}
            >
              <div>
                No {storeLabel} in this area for your search, try changing
                search filters or location
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
