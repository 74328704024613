import { CircularProgress, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { cloneDeep, merge, pick } from "lodash";
import React, { Component, useEffect } from "react";
import TagManager from "react-gtm-module";
import MapContext from "../../Contexts/MapContext";
import UserContext from "../../Contexts/UserContext";
import {
  axios,
  getConfig,
  getProperties,
  getUserLocation,
} from "../../Services/Data.service";
import { getUser, loginDathic } from "../../Services/auth.service";
import { useRetryRequest } from "../../Utils/hooks";
import Map from "./Map";
import PoweredByDathic from "./PoweredByDathic";

const sensitiveConfigAttributes = [
  "status",
  "additionalSources",
  "notificationEmail",
];

const LoginHandler = ({ loginRequest }) => {
  const {
    isRequesting: isLoggingIn,
    requestSuccess: loginSuccess,
    retry: retryLogin,
  } = useRetryRequest(loginRequest, { maxTries: 5 });

  useEffect(() => {
    axios.interceptors.response.use(undefined, function (error) {
      let filename = "";
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const config = error.response?.config;
        if ([422, 401].includes(error.response.status)) {
          // Handle bad request error or auth error
          retryLogin();
          return Promise.reject(error);
        } else if (
          error.response?.status === 400 &&
          config?.url?.includes("locate_ipaddress")
        ) {
          return Promise.reject(error);
        }
        filename =
          config?.method || config?.url
            ? `${config?.method} ${config?.url}`
            : JSON.stringify(config || error.response).trim() ||
              "Data.service.js";
      } else if (error.request) {
        // The request was made but no response was received
        filename =
          "No response received in Data.service.js: " +
          JSON.stringify(error).trim();
      } else {
        // Something happened in setting up the request that triggered an Error
        filename =
          "Error while making request in Data.service.js: " +
          JSON.stringify(error).trim();
      }
      window.spDathic("trackError", {
        ...error,
        message: `${error.response?.status || ""} ${
          error.message || "No message provided."
        }`,
        filename,
      });
      return Promise.reject(error);
    });
  }, [retryLogin]);

  return !loginSuccess ? (
    <Paper
      sx={{
        position: "absolute",
        zIndex: 3,
        padding: "0px 8px",
        bottom: 0,
        right: 0,
        backgroundColor: "rgba(252,252,252,0.25)",
        color: "#032536",
        fontSize: 11,
        display: "flex",
        alignItems: "center",
      }}
    >
      Preparing the locator...
    </Paper>
  ) : (
    <></>
  );
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.loginRequest = this.loginRequest.bind(this);
  }

  replaceFavicon(url) {
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = url;
  }

  async checkAdminAuth() {
    const params = new URLSearchParams(window.location.search);
    const adminToken = params.get("adminToken");
    const adminUserId = params.get("adminUserId");
    if (!adminToken || !adminUserId) {
      return;
    }

    const userFromDB = await getUser(adminUserId, adminToken);
    if (userFromDB && !userFromDB.length) {
      if (userFromDB.active === false) {
        return;
      }
      const resources = userFromDB.roles?.reduce(
        (resources, roleItem) =>
          Array.from(
            new Set([
              ...resources,
              ...(roleItem.role.resources?.map(
                (resource) => resource.resource_id
              ) || []),
            ])
          ),
        []
      );
      let adminUser = {
        ...userFromDB,
        resources,
      };
      this.context.setState((oldState) => ({
        ...(oldState || {}),
        adminUser,
      }));
    } else {
      return;
    }
  }

  async loginRequest() {
    const params = new URLSearchParams(window.location.search);
    try {
      let dathicCredential = await loginDathic(
        process.env.REACT_APP_DEFAULT_USERNAME,
        process.env.REACT_APP_DEFAULT_USERPWD
      );
      if (!dathicCredential?.access_token?.length) {
        return;
      }
      const { user_id, access_token: accessToken } = dathicCredential;
      sessionStorage.setItem("dathic_access_token", accessToken);
      let properties = await getProperties();
      let config = await getConfig();
      const storeLocatorConfigFromParam = JSON.parse(
        decodeURIComponent(params.get("storeLocatorConfig") || "{}")
      );
      const storeLocatorConfig = cloneDeep(config?.store_locator || {});
      if (storeLocatorConfig?.initialCoordinatesFromIP ?? true) {
        try {
          const countries = properties?.find((p) => p.name === "countries")
            ?.value;
          const locationRes = await getUserLocation(countries);
          if (locationRes && typeof locationRes !== "string") {
            merge(storeLocatorConfig, {
              initialCoordinates: {
                latitude: locationRes.location.latitude,
                longitude: locationRes.location.longitude,
              },
            });
          }
        } catch (e) {
          console.log(e);
        }
      }
      merge(
        storeLocatorConfig,
        pick(
          storeLocatorConfigFromParam,
          Object.keys(storeLocatorConfigFromParam).filter(
            (key) => !sensitiveConfigAttributes.includes(key)
          )
        )
      );
      this.context.setState((oldState) => ({
        ...(oldState || {}),
        accessToken: accessToken,
        userId: user_id,
        properties,
        config: storeLocatorConfig,
        orgProperties: config?.properties,
      }));
      const organizationId = window.DATHIC_ORG_ID;
      const org_id = organizationId || process.env.REACT_APP_ORG_ID;
      TagManager.initialize({
        gtmId: "GTM-M7P8TV3",
        events: {
          flavorFilterChange: "flavorFilterChange",
          chainFilterChange: "chainFilterChange",
          typeFilterChange: "typeFilterChange",
          componentVisible: "componentVisible",
          searchChange: "searchChange",
        },
        dataLayer: {
          GAMeasurementId: storeLocatorConfig?.GAMeasurementId,
          UATrackingId: storeLocatorConfig?.UATrackingId,
          organization_id: org_id,
        },
      });
      if (storeLocatorConfig?.faviconUrl) {
        this.replaceFavicon(storeLocatorConfig.faviconUrl);
      }
      return accessToken;
    } catch (e) {
      console.log(e);
      return;
    }
  }

  componentDidMount() {
    window.spDathic("enableFormTracking");
    this.checkAdminAuth();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.context.state?.config) {
      window.spDathic("enableFormTracking");
    }
  }

  render() {
    const { region } = this.props.match?.params || {};
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    const storelocatorInactive =
      this.context.state?.config?.status === "inactive";

    const containerHeight = window !== window.top ? "100%" : window.innerHeight;
    return storelocatorInactive ? (
      <></>
    ) : (
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        width="100vw"
        height={containerHeight}
      >
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          height={isMobile ? undefined : "100%"}
        >
          <Box flex={1} width="100%">
            <PoweredByDathic />
            {this.context.state?.config ? (
              <MapContext.Consumer>
                {(mapContext) => (
                  <Map
                    region={region}
                    storeLocatorConfig={this.context.state?.config}
                    orgProperties={this.context.state?.orgProperties}
                    mapContext={mapContext}
                  />
                )}
              </MapContext.Consumer>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </div>
            )}
            <LoginHandler loginRequest={this.loginRequest} />
          </Box>
        </Box>
      </Box>
    );
  }
}
Home.contextType = UserContext;

export default Home;
