import axiosLibrary from "axios";
import { calculateMaxMi, decodeFromBase64 } from "../Utils/Utils";

export const RESULT_LIMIT = 6000;
const GET_CONFIG = "api/store_locator/config";
const GET_STORE_LIST = "api/store_locator/stores";
const GET_STORE_LIST_STREAM = "api/store_locator/stores_stream";
const GET_ZIPCODES_IN_RADIUS = "api/zipcodes_in_radius";
const GET_GEOLOCATE_IP = "api/locate_ipaddress";
const GET_PROPERTIES = "api/store_locator/properties";
const GET_PRODUCT_LIST = "api/store_locator/products";
const GET_PRODUCT_STORE_LIST = "api/store_locator/product_store";
const GET_DELIVERY_ENTITY_STORE_LIST = "api/delivery/store_delivery_entity";
export const BASE_URL = "https://api-1.dathic.com/";
const STORE_SEARCH = "api/search_stores";
const PLACE_SEARCH = "api/store_locator/open_store";
const STORE_REVIEWS = "api/store_locator/store_reviews";
const POST_SUGGESTION = "api/store_locator/stores_suggested";
const api_store_inventory = "api/store_locator/inventory";
const api_states_from_bounds = "api/states_from_bounds";

const organizationId = window.DATHIC_ORG_ID;
const org_id = "/" + (organizationId || process.env.REACT_APP_ORG_ID);

let orgReplacement = undefined;
try {
  const orgReplacement64 = new URLSearchParams(window.location.search).get(
    "orgReplacement"
  );
  orgReplacement = decodeFromBase64(orgReplacement64);
} catch (e) {}
orgReplacement =
  "/" + (orgReplacement || organizationId || process.env.REACT_APP_ORG_ID);

export const axios = axiosLibrary.create({
  baseURL: BASE_URL,
});

axios.interceptors.request.use((config) => {
  const accessToken = sessionStorage.getItem("dathic_access_token");
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

export async function getConfig() {
  try {
    const result = await axios.get(BASE_URL + GET_CONFIG + org_id);

    return result.data;
  } catch (error) {
    return { data: [] };
  }
}

export async function getProperties(endpoint, parameters) {
  try {
    let params;
    if (Object.values(parameters || {}).filter((val) => !!val).length) {
      params = new URLSearchParams();
      Object.keys(parameters).forEach((key) => {
        if (typeof parameters[key] !== "string" && parameters[key]?.length) {
          parameters[key].forEach((param) => {
            params.append(key, param);
          });
        } else if (
          ["string", "number", "boolean"].includes(typeof parameters[key])
        ) {
          params.append(key, parameters[key]);
        }
      });
    }
    let url = BASE_URL;
    url = url + GET_PROPERTIES + (endpoint ? `/${endpoint}` : "") + org_id;
    const result = await axios.get(url, {
      params,
    });

    return result?.data;
  } catch (error) {
    return [];
  }
}

export async function doSearch(
  coordinates,
  zipcode,
  zoom,
  chain = "*",
  products = [],
  q = [
    "products",
    "chain",
    "type",
    "json_content",
    "start_date",
    "end_date",
    "recurrence_rule",
    "secondary_images",
    "name",
    "city",
    "image_url_large",
    "image_url",
    "address",
    "featured_content",
    "state",
    "zipcode",
    "phone",
    "email",
    "hot_box",
    "county",
    "yelp_rating",
    "yelp_review_count",
    "google_place_review_count",
    "google_place_rating",
    "link_buy",
    "description",
  ],
  fallback_to_stores = false,
  controller,
  limit = RESULT_LIMIT
) {
  try {
    let miles;
    if (coordinates && zoom) {
      miles = calculateMaxMi(coordinates[0], zoom);
    }

    const params = new URLSearchParams();
    params.append("term", chain || "*");
    params.append("location", zipcode);
    coordinates && params.append("coordinates", JSON.stringify(coordinates));
    params.append("search_limit", limit);
    params.append("max_mi", miles || 0);
    params.append("fallback_to_stores", fallback_to_stores);
    let paramsObj = {
      products,
      q,
    };
    Object.keys(paramsObj).forEach((key) => {
      paramsObj[key].forEach((param) => {
        params.append(key, param);
      });
    });

    const result = await axios.get(BASE_URL + GET_STORE_LIST + orgReplacement, {
      params,
    });

    return result.data;
  } catch (error) {
    return [];
  }
}

export async function doSearchStream(
  coordinates,
  zipcode,
  zoom,
  chain = "*",
  products = [],
  q = [
    "products",
    "chain",
    "type",
    "json_content",
    "start_date",
    "end_date",
    "recurrence_rule",
    "secondary_images",
    "name",
    "city",
    "image_url_large",
    "image_url",
    "address",
    "featured_content",
    "state",
    "zipcode",
    "phone",
    "email",
    "hot_box",
    "county",
    "yelp_rating",
    "yelp_review_count",
    "google_place_review_count",
    "google_place_rating",
    "link_buy",
    "description",
  ],
  fallback_to_stores = false,
  controller
) {
  try {
    const accessToken = sessionStorage.getItem("dathic_access_token");
    const miles = calculateMaxMi(coordinates[0], zoom);

    const url = new URL(BASE_URL + GET_STORE_LIST_STREAM + orgReplacement);
    url.searchParams.append("term", chain || "*");
    url.searchParams.append("location", zipcode);
    url.searchParams.append("coordinates", JSON.stringify(coordinates));
    url.searchParams.append("search_limit", RESULT_LIMIT);
    url.searchParams.append("max_mi", miles);
    url.searchParams.append("fallback_to_stores", fallback_to_stores);
    let paramsObj = {
      products,
      q,
    };
    Object.keys(paramsObj).forEach((key) => {
      paramsObj[key].forEach((param) => {
        url.searchParams.append(key, param);
      });
    });

    const response = await fetch(url, {
      signal: controller.signal,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (error) {
    if (error.name === "AbortError") {
      console.log("Previous request canceled");
    } else {
      console.error("Error fetching stores:", error);
    }
    return [];
  }
}

// Get data from the cache.
async function getCachedData(cacheName, url) {
  const cache = await caches.open(cacheName);

  // Check if the response is already cached
  const response = await cache.match(url);
  let res;
  if (response) {
    // Check if the cached response has expired
    const cacheHeaders = response.headers;
    const expirationTime = cacheHeaders.get("X-Expiration-Time"); // Assume the header contains the expiration time

    if (expirationTime && Date.now() > parseInt(expirationTime)) {
      // Response has expired, remove it from cache
      cache.delete(url);
    } else {
      // Response is still valid, use the cached response
      res = await response.json();
    }
  }
  return { cache, res };
}

const itemsFetchingOpenStore = new Set();
export async function doSearchInGooglePlaces(store_id) {
  if (itemsFetchingOpenStore.has(store_id)) {
    return;
  }
  try {
    const cacheName = "dathic_open_store";
    const url = BASE_URL + PLACE_SEARCH + `/${store_id}`;
    const { cache, res } = await getCachedData(cacheName, url);
    if (res) return res;

    itemsFetchingOpenStore.add(store_id);
    // Response is not in cache or has expired, fetch and cache a fresh response
    const newResponse = await fetch(url);
    // Clone the response because it can only be consumed once
    const responseClone = newResponse.clone();

    const cacheHeaders = new Headers(newResponse.headers);
    cacheHeaders.append(
      "X-Expiration-Time",
      (Date.now() + 5 * 60 * 1000).toString()
    ); // Set expiration to 5 min from now
    await cache.put(
      url,
      new Response(responseClone.body, {
        status: newResponse.status,
        statusText: newResponse.statusText,
        headers: cacheHeaders,
      })
    );
    itemsFetchingOpenStore.delete(store_id);
    return newResponse.json();
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function doGetProducts() {
  try {
    const result = await axios.get(
      BASE_URL + GET_PRODUCT_LIST + orgReplacement,
      {
        params: {
          only_store_related: true,
        },
      }
    );

    return result.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getProductsForStores(store_id = []) {
  try {
    const params = new URLSearchParams();
    let paramsObj = {
      store_id,
    };
    Object.keys(paramsObj).forEach((key) => {
      paramsObj[key].forEach((param) => {
        params.append(key, param);
      });
    });
    const result = await axios.get(
      BASE_URL + GET_PRODUCT_STORE_LIST + orgReplacement,
      {
        params,
      }
    );

    return result.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getDeliveryEntitiesForStores(store_id = []) {
  try {
    const params = new URLSearchParams();
    let paramsObj = {
      store_id,
    };
    Object.keys(paramsObj).forEach((key) => {
      paramsObj[key].forEach((param) => {
        params.append(key, param);
      });
    });
    const result = await axios.get(BASE_URL + GET_DELIVERY_ENTITY_STORE_LIST, {
      params,
    });

    return result.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

const itemsFetchingStoreReview = new Set();
export async function doGetStoreReviews(store_id) {
  if (itemsFetchingStoreReview.has(store_id)) {
    return;
  }
  try {
    const cacheName = "dathic_store_reviews";
    const url = BASE_URL + STORE_REVIEWS + `/${store_id}`;
    const { cache, res } = await getCachedData(cacheName, url);
    if (res) return res;

    itemsFetchingStoreReview.add(store_id);
    // Response is not in cache or has expired, fetch and cache a fresh response
    const newResponse = await fetch(url);
    // Clone the response because it can only be consumed once
    const responseClone = newResponse.clone();

    const cacheHeaders = new Headers(newResponse.headers);
    cacheHeaders.append(
      "X-Expiration-Time",
      (Date.now() + 5 * 60 * 1000).toString()
    ); // Set expiration to 5 min from now
    await cache.put(
      url,
      new Response(responseClone.body, {
        status: newResponse.status,
        statusText: newResponse.statusText,
        headers: cacheHeaders,
      })
    );
    itemsFetchingStoreReview.delete(store_id);
    return newResponse.json();
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function doSubmitReview(
  store_id,
  name,
  email,
  topic,
  comment,
  rating
) {
  try {
    const params = {
      user_id: email,
      title: topic,
      comment: comment,
      rating: rating,
    };

    const result = await axios.post(
      BASE_URL + STORE_REVIEWS + `/${store_id}`,
      params
    );

    return result.data;
  } catch (error) {
    return null;
  }
}

export async function doSubmitSuggestion(suggestion) {
  try {
    const result = await axios.post(
      BASE_URL + POST_SUGGESTION + org_id,
      suggestion
    );

    return result.data;
  } catch (error) {
    return null;
  }
}

export async function doSearchStores(
  stores,
  search_org,
  distance_threshold_meters,
  org_id
) {
  try {
    const body = { stores, search_org, distance_threshold_meters, org_id };
    let url = BASE_URL;
    url = url + STORE_SEARCH;
    const result = await axios.post(url, body);

    return result?.data;
  } catch (error) {
    return null;
  }
}

export async function getStoreLocatorInventory(
  entity_type = [],
  zipcode = [],
  delivery_product_uid = [],
  org_id = 0,
  q = []
) {
  try {
    let url = BASE_URL;
    url = url + api_store_inventory + (org_id ? `/${org_id}` : "");

    let params = new URLSearchParams();
    let paramsObj = {
      entity_type,
      zipcode,
      delivery_product_uid,
      q,
    };
    Object.keys(paramsObj).forEach((key) => {
      paramsObj[key].forEach((param) => {
        params.append(key, param);
      });
    });
    const result = await axios.get(url, {
      params: params,
    });

    return result?.data;
  } catch (error) {
    throw error;
  }
}

export async function getStatesFromBounds(sw_lng, sw_lat, ne_lng, ne_lat) {
  try {
    let url = BASE_URL;
    url = url + api_states_from_bounds;

    const result = await axios.get(url, {
      params: {
        sw_lat,
        sw_lng,
        ne_lat,
        ne_lng,
      },
    });

    return result?.data;
  } catch (error) {
    throw error;
  }
}

export async function getZipcodesInRadius(latitude, longitude, radius_mi) {
  try {
    let params = new URLSearchParams();
    params.append("latitude", latitude);
    params.append("longitude", longitude);
    params.append("radius_mi", radius_mi);
    let url = BASE_URL;
    url = url + GET_ZIPCODES_IN_RADIUS;
    const result = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
      params,
    });

    return result.data?.zipcodes || [];
  } catch (error) {
    throw error;
  }
}

export async function getUserLocation(country = []) {
  try {
    let params = new URLSearchParams();
    let paramsObj = {
      country,
    };
    Object.keys(paramsObj).forEach((key) => {
      paramsObj[key].forEach((param) => {
        params.append(key, param);
      });
    });
    let url = BASE_URL;
    url = url + GET_GEOLOCATE_IP;
    const result = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
      params,
    });

    return result.data;
  } catch (error) {
    throw error;
  }
}
