import { FlyToInterpolator } from "@deck.gl/core";
import React from "react";
import { DEFAULT_MAP_ZOOM } from "../Utils/Utils";
import { US_POS } from "../Views/Home/Map";

const MapContext = React.createContext();

export const MapContextProvider = (props) => {
  const [mapContext, setMapContext] = React.useState({
    layers: [],
    highlight_result: { data: null },
    inTransition: false,
    isDragging: false,
    isZooming: false,
    viewport: {
      width: window.innerWidth,
      height: window.innerHeight,
      latitude: US_POS.lat,
      longitude: US_POS.lng,
      zoom: DEFAULT_MAP_ZOOM,
      bearing: 0,
      pitch: 0,
      transitionDuration: 1000,
      transitionInterpolator: new FlyToInterpolator(),
      trackUserLocation: true,
    },
    deliveryInventory: null,
    products: undefined,
    searchFilters: { chain: "", type: "", product: "" },
    hoverInfo: undefined,
    hoverResultItem: undefined,
    layerUpdates: 0,
  });

  return (
    <MapContext.Provider value={{ ...mapContext, setMapContext }}>
      {props.children}
    </MapContext.Provider>
  );
};

export default MapContext;
