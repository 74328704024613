import React from "react";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (`${error}`.includes("Failed to execute 'getImageData'")) {
      return;
    }
    console.log("error boundary", error, errorInfo);
    window.spDathic("trackError", {
      ...error,
      message: `${error}`,
      filename: "ErrorBoundary",
    });
    setTimeout(() => {
      window.history.pushState({}, "", "?");
      window.parent.postMessage(
        {
          action: "update-dathic-locator-params",
          data: "",
        },
        "*"
      );
      window.location.reload();
    }, 2000);
  }

  render() {
    return this.props.children;
  }
}
