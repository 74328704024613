import PropTypes from "prop-types";
import React, { Component } from "react";

import { grey } from "@mui/material/colors";
import { withTheme } from "@mui/styles";
import { Box } from "@mui/system";
import VirtualList from "react-tiny-virtual-list";
import { AutoSizer, List } from "react-virtualized";
import "react-virtualized/styles.css";
import MapContext from "../../Contexts/MapContext";
import UserContext from "../../Contexts/UserContext";
import ResultItem from "./ResultItem";

let userContext;

class ResultList extends Component {
  static contextType = MapContext;

  constructor(props) {
    super(props);

    this.rowRenderer = this.rowRenderer.bind(this);
  }

  rowRenderer = ({ index, isScrolling, key, style }) => {
    const { data, showResultDetailsPage } = this.props;
    return (
      <div key={data[index].id} style={{ ...style, paddingBottom: 10 }}>
        <UserContext.Consumer>
          {(context) => (
            <ResultItem
              key={data[index].id}
              data_index={index}
              res={data[index]}
              showResultDetailsPage={showResultDetailsPage}
              storeLocatorConfig={context.state?.config}
            />
          )}
        </UserContext.Consumer>
      </div>
    );
  };

  getRowHeight = (i) => {
    const base = 74;
    const spacing = 10;
    const storeLocatorConfig = userContext?.state?.config;
    // type,addessx2,reviews,stars,open
    const visibleLines = [
      storeLocatorConfig?.storeDetails?.showType && 18,
      storeLocatorConfig?.storeDetails?.showAddress && 18,
      storeLocatorConfig?.storeDetails?.showAddress && 18,
      storeLocatorConfig?.storeDetails?.showReviews &&
        !!storeLocatorConfig?.storeDetails?.numberOfReviewsToShow &&
        24,
      storeLocatorConfig?.storeDetails?.showReviews && 22,
      storeLocatorConfig?.storeDetails?.openInfo && 21,
    ]
      .filter((l) => !!l)
      .reduce((acc, size) => acc + size, 0);
    return base + spacing + visibleLines;
  };

  setUserContext = (context) => {
    userContext = context;
  };

  render() {
    const { data, showResultDetailsPage } = this.props;
    const list = (context) =>
      window.matchMedia("(max-width: 768px)").matches ? (
        <VirtualList
          style={{
            position: "relative",
            zIndex: 1000,
            overflowY: "hidden",
          }}
          width="100%"
          height={this.getRowHeight() + 20}
          itemCount={this.props.data ? this.props.data.length : 0}
          itemSize={300} // Also supports variable heights (array or function getter)
          scrollDirection={"horizontal"}
          renderItem={({ index, style }) => (
            <div
              key={data[index].id}
              style={{ ...style, padding: 10, height: 155 }}
            >
              <Box
                width={60}
                height={6}
                backgroundColor={grey[200]}
                borderRadius={3}
                position="absolute"
                top={18}
                left="calc(50% - 30px)"
              ></Box>
              <ResultItem
                key={data[index].id}
                data_index={index}
                res={data[index]}
                showResultDetailsPage={showResultDetailsPage}
                storeLocatorConfig={context.state?.config}
              />
            </div>
          )}
        />
      ) : (
        <AutoSizer style={{ height: "100%", width: "100%" }}>
          {({ height, width }) => {
            return (
              <List
                height={height}
                rowCount={this.props.data ? this.props.data.length : 0}
                rowHeight={this.getRowHeight}
                spacing={10}
                rowRenderer={this.rowRenderer}
                width={width}
                overscanRowCount={3}
              />
            );
          }}
        </AutoSizer>
      );

    return (
      <UserContext.Consumer>
        {(context) => (
          <>
            {this.setUserContext(context)}
            {list(context)}
          </>
        )}
      </UserContext.Consumer>
    );
  }
}

ResultList.propTypes = {
  data: PropTypes.array.isRequired,
  showResultDetailsPage: PropTypes.func.isRequired,
};
export default withTheme(ResultList);
