import { Search } from "@mui/icons-material";
import {
  Button,
  MenuItem,
  Paper,
  Skeleton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Stack } from "@mui/system";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import Geocode from "react-geocode";
import TagManager from "react-gtm-module";
import UserContext from "../../Contexts/UserContext";
import { loginDathic } from "../../Services/auth.service";
import {
  doGetProducts,
  getConfig,
  getProperties,
} from "../../Services/Data.service";
import { DEFAULT_MAP_ZOOM, ShowMessage, startCase } from "../../Utils/Utils";
import PlacesAutocomplete, {
  geocodeBySuggestion,
} from "../Components/PlacesAutocomplete";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);

export default function SearchBlock() {
  const params = new URLSearchParams(window.location.search);
  const theme = useTheme();
  const [term, setTerm] = useState("");
  const [filters, setFilters] = useState({ chain: "", type: "", product: "" });
  const [zipcode, setZipcode] = useState();
  const [geopoliticalRegion, setGopoliticalRegion] = useState();
  const [coordinates, setCoordinates] = useState();
  const [products, setProducts] = useState([]);
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);

  const userContext = useContext(UserContext);

  const storeLabel =
    userContext.state?.orgProperties?.storeNameReplacement || "Store";
  const storeTypeLabel =
    userContext.state?.orgProperties?.storeTypeNameReplacement || "Type";
  const productLabel =
    userContext.state?.orgProperties?.productNameReplacement || "Product";
  const chainLabel =
    userContext.state?.orgProperties?.chainNameReplacement || "Retailer";

  const INITIAL_LATITIUDE =
    userContext.state?.config?.initialCoordinates?.latitude || 29.617821;
  const INITIAL_LONGITUDE =
    userContext.state?.config?.initialCoordinates?.longitude || -81.70622;
  const INITIAL_ZOOM =
    userContext.state?.config?.initialZoom || DEFAULT_MAP_ZOOM;

  const search = () => {
    let shouldFlyToCoordinates = coordinates;
    if (!shouldFlyToCoordinates?.lat || !shouldFlyToCoordinates?.lng) {
      shouldFlyToCoordinates = {
        lat: INITIAL_LATITIUDE,
        lng: INITIAL_LONGITUDE,
      };
    }
    const zoom = INITIAL_ZOOM;
    const _coordinates = [
      shouldFlyToCoordinates.lat,
      shouldFlyToCoordinates.lng,
    ];

    const url = new URL(
      params.get("storeLocatorUrl") ||
        userContext.state?.orgProperties?.storeLocatorUrl
    );
    url.searchParams.append("term", term);
    url.searchParams.append("zoom", zoom);
    url.searchParams.append("coordinates", _coordinates);
    url.searchParams.append("filters", JSON.stringify(filters));
    if (zipcode) url.searchParams.append("zipcode", zipcode);
    if (geopoliticalRegion)
      url.searchParams.append("geopoliticalRegion", geopoliticalRegion);
    window.open(url, "_blank");
  };

  const searchWithTerm = async () => {
    if (term === "") {
      ShowMessage("Please enter a valid address or zipcode");
      return;
    }
    TagManager.dataLayer({
      dataLayer: {
        event: "searchChange",
        category: "Search",
        action: "Zipcode or Address:" + term,
      },
    });
    const data = await Geocode.fromAddress(term);

    if (data.results[0]) {
      const { geometry } = data.results[0];

      const coordinates = {
        lat: geometry.location.lat,
        lng: geometry.location.lng,
      };

      setCoordinates(coordinates);
      setZipcode(term);
      setGopoliticalRegion(data.results[0].types[0]);
    }
  };

  const handleKeyDown = (event) => {
    setTerm(event.target.value || "");

    if (event.key === "Enter") {
      setAutocompleteOpen(false);
      searchWithTerm();
    }
  };

  const onSuggestionSelected = (suggestion) => {
    setTerm(suggestion.description);
    geocodeBySuggestion(suggestion)
      .then((results) => {
        if (results.length < 1) {
          alert(
            "Geocode request completed successfully but without any results"
          );
        }

        const zipcodeIndex = _.findKey(results[0].address_components, {
          types: ["postal_code"],
        });
        const zipcode =
          zipcodeIndex > -1
            ? results[0].address_components[zipcodeIndex].long_name
            : "";

        const { geometry } = results[0];

        const coordinates = {
          lat: geometry.location.lat(),
          lng: geometry.location.lng(),
        };

        setCoordinates(coordinates);
        setZipcode(zipcode);
        setGopoliticalRegion(suggestion.types[0]);

        TagManager.dataLayer({
          dataLayer: {
            event: "searchChange",
            category: "Search",
            action: "Zipcode (from google places): " + suggestion.description,
          },
        });
      })
      .catch((err) => {
        ShowMessage(undefined, {
          message: err.message,
          filename: "SearchBlock.js:geocodeBySuggestion",
          error: err,
        });
      });
  };

  const doReset = () => {
    setTerm("");
  };

  const getFilterList = {
    Product: (products || [])
      .sort((a, b) => _.toLower(a.name).localeCompare(_.toLower(b.name)))
      .reduce(
        (acc, p) => acc.set(p.id, startCase(_.toLower(p.name))),
        new Map()
      ),
    Chain: (
      userContext.state?.properties?.find(
        (properties) => properties.name === "chain"
      )?.value || []
    )
      .sort((a, b) => _.toLower(a).localeCompare(_.toLower(b)))
      .reduce((acc, p) => acc.set(p, startCase(_.toLower(p))), new Map()),
    Type: (
      userContext.state?.properties?.find(
        (properties) => properties.name === "type"
      )?.value || []
    )
      .sort((a, b) => _.toLower(a).localeCompare(_.toLower(b)))
      .reduce((acc, p) => acc.set(p, startCase(_.toLower(p))), new Map()),
  };

  useEffect(() => {
    loginDathic(
      process.env.REACT_APP_DEFAULT_USERNAME,
      process.env.REACT_APP_DEFAULT_USERPWD
    )
      .then((dathicCredential) => {
        let accessToken;
        if (dathicCredential?.access_token?.length) {
          const { user_id, access_token } = dathicCredential;
          accessToken = access_token;
          userContext.setState((oldState) => ({
            ...(oldState || {}),
            accessToken: access_token,
            userId: user_id,
          }));
          getProperties(accessToken).then((properties) =>
            userContext.setState((oldState) => ({
              ...(oldState || {}),
              properties,
            }))
          );
        }
        doGetProducts(accessToken).then((response) =>
          setProducts(response || [])
        );
        return getConfig(accessToken);
      })
      .then((config) => {
        userContext.setState((oldState) => ({
          ...(oldState || {}),
          config: config?.store_locator,
          orgProperties: config?.properties,
        }));
        const organizationId = window.DATHIC_ORG_ID;
        const org_id = "/" + (organizationId || process.env.REACT_APP_ORG_ID);
        TagManager.initialize({
          gtmId: "GTM-M7P8TV3",
          events: {
            flavorFilterChange: "flavorFilterChange",
            chainFilterChange: "chainFilterChange",
            typeFilterChange: "typeFilterChange",
            componentVisible: "componentVisible",
            searchChange: "searchChange",
          },
          dataLayer: {
            GAMeasurementId: config?.store_locator?.GAMeasurementId,
            UATrackingId: config?.store_locator?.UATrackingId,
            organization_id: org_id,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.setState]);

  const handleSubmit = (e) => {
    e.preventDefault();
    search();
  };

  const getFilterTypeName = (filterType) => {
    switch ((filterType || "").toLowerCase()) {
      case "type":
        return storeTypeLabel;
      case "product":
        return productLabel;
      case "chain":
        return chainLabel;

      default:
        return filterType;
    }
  };

  useEffect(() => {
    if (!!userContext.state?.config) {
      window.spDathic("enableFormTracking");
    }
  }, [userContext.state]);

  return (
    <form
      id="search-block-form"
      style={{
        width: "100vw",
        height: "100vh",
        backgroundImage: `url(${
          userContext.state?.config?.searchBlockBackground ||
          "https://dathicstorelocator.s3.amazonaws.com/app/static/media/search-block-bg.png"
        })`,
        backgroundSize: "cover",
        borderRadius: theme.shape.borderRadius,
        overflow: "hidden",
      }}
      onSubmit={handleSubmit}
    >
      <Stack
        width="100%"
        height="100%"
        direction="column"
        alignItems="center"
        spacing={3}
        padding={2}
        style={{
          backdropFilter: "brightness(0.4)",
          WebkitBackdropFilter: "brightness(0.4)",
        }}
        justifyContent="center"
      >
        {!userContext.state?.config ? (
          <Skeleton
            variant="text"
            sx={{ fontSize: "1.5rem", width: "24rem" }}
          />
        ) : (
          <Typography
            variant="h5"
            color="white"
            fontWeight="bold"
            textAlign="center"
          >
            {userContext.state?.config?.searchBlockTitle ||
              `${storeLabel} Locator Search`}
            {userContext.state?.config?.searchBlockSubTitle && (
              <Typography color="white" fontWeight="bold">
                {userContext.state?.config?.searchBlockSubTitle}
              </Typography>
            )}
          </Typography>
        )}
        {!userContext.state?.config ? (
          <Skeleton variant="rounded" width={300} height={170} />
        ) : (
          <Paper
            elevation={0}
            style={{
              padding: 10,
              background: theme.palette.primary.main,
              width: 300,
            }}
          >
            <Stack direction="column" spacing="10px" height="100%">
              <PlacesAutocomplete
                open={autocompleteOpen}
                onClose={() => setAutocompleteOpen(false)}
                onOpen={() => setAutocompleteOpen(true)}
                textFieldProps={{
                  onKeyDown: (event) => handleKeyDown(event),
                  onChange: (e) => setTerm(e.target.value || ""),
                  fullWidth: true,
                  margin: "dense",
                  value: term,
                  placeholder: "Address / Zipcode",
                  name: "address",
                }}
                onSuggestionSelected={(e) => {
                  if (e) {
                    onSuggestionSelected(e);
                  } else {
                    doReset();
                  }
                }}
                createAutocompleteRequest={(inputValue) => {
                  const isSearchingAddress = /\d+.*[A-z]/.exec(inputValue);
                  return {
                    input: inputValue,
                    types: [isSearchingAddress ? "geocode" : "(regions)"],
                  };
                }}
              />
              <Stack direction="row" spacing="10px" width="100%">
                {userContext.state?.config?.searchFilters?.map((filterType) => (
                  <TextField
                    select
                    value={filters[filterType.toLowerCase()]}
                    onChange={(e) => {
                      const option = Array.from(
                        getFilterList[filterType].entries()
                      ).find((entry) => entry[0] === e.target.value);
                      const value = option?.[0] || "";
                      const label = option?.[1] || "";
                      setFilters({
                        ...filters,
                        [filterType.toLowerCase()]: value,
                      });
                      TagManager.dataLayer({
                        dataLayer: {
                          event:
                            filterType === "Chain"
                              ? "chainFilterChange"
                              : filterType === "Product"
                              ? "flavorFilterChange"
                              : "typeFilterChange",
                          category: filterType + " Filter",
                          label: value,
                          data: label,
                          snowplowParameters: {
                            terms: [`${value}`, `${label}`],
                            filters: { [filterType + "-Filter"]: `${value}` },
                          },
                        },
                      });
                    }}
                    label={getFilterTypeName(filterType)}
                    variant="filled"
                    size="small"
                    name={`filter-${filterType}`}
                    fullWidth
                  >
                    <MenuItem value={""} sx={{ fontSize: 14 }}>
                      All
                    </MenuItem>
                    {Array.from(getFilterList[filterType].entries()).map(
                      (pair) => (
                        <MenuItem value={pair[0]} sx={{ fontSize: 14 }}>
                          {startCase(_.toLower(pair[1]))}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                ))}
              </Stack>
              <Button
                type="submit"
                variant="text"
                sx={{ color: theme.palette.primary.contrastText }}
                startIcon={<Search />}
              >
                Search
              </Button>
            </Stack>
          </Paper>
        )}
      </Stack>
    </form>
  );
}
